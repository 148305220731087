export default function priceFormatter(price: string | number | undefined | null) {
  if (price === undefined || price === null || price === 0 || price === '0') {
    return 'n.t.b.';
  }

  const withDots = (val: string) => val.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  const [int, decimal] = price.toString().split('.');
  const modifiedDecimal = decimal ? parseFloat(`0.${decimal}`).toFixed(2).substring(2) : '-';
  return `€ ${withDots(int)},${modifiedDecimal}`;
}

export function isNumber(num: string | number | undefined | null) {
  if (num === undefined || num === null) {
    return false;
  }

  if (num.toString().length === 0) {
    return false;
  }

  const regExp = /^\d*$/;
  return regExp.test(num.toString());
}
