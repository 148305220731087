import React from 'react';
import Box from '@mui/material/Box';
import { ReactComponent as StylepartnerLogo } from '../../../assets/images/stylepartnerLogo.svg';
import { ReactComponent as StylepartnerLogoBlack } from '../../../assets/images/stylepartnerLogoBlack.svg';
import styled from 'styled-components';
import { Colors } from '../../../utils/constant';

const StyledHeaderText = styled.p<{ $inverted: boolean }>`
  color: ${(props) => (props.$inverted ? Colors.CharcoalGrey : Colors.White)};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`;

const StyledHeaderContainer = styled(Box)<{ $background: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px 32px 0;
  width: 100%;
  background-color: ${(props) => props.$background};
`;

const StyledButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const ButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface PageHeaderProps {
  background?: string;
  disabled?: boolean;
  isSmallScreen?: boolean;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  onButtonClick?: () => void;
  buttonDisabled?: boolean;
  inverted?: boolean;
}

export default function PageHeader({
  background = 'transparent',
  disabled = false,
  buttonText,
  buttonIcon,
  onButtonClick = () => {},
  buttonDisabled = false,
  inverted = false,
}: PageHeaderProps) {
  function getBgColor() {
    if (background?.includes('rgba') && disabled) {
      return `${background.substring(0, background?.length - 2)}0.6)`;
    }
    return background;
  }

  return (
    <StyledHeaderContainer $background={getBgColor()}>
      {inverted ? <StylepartnerLogoBlack width={140} height={25} /> : <StylepartnerLogo width={140} height={25} />}
      <StyledButton onClick={onButtonClick} disabled={buttonDisabled}>
        <ButtonContainer gap={1}>
          {!!buttonText && <StyledHeaderText $inverted={inverted}>{buttonText}</StyledHeaderText>}
          {!!buttonIcon && buttonIcon}
        </ButtonContainer>
      </StyledButton>
    </StyledHeaderContainer>
  );
}
