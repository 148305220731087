import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MyQuoteState, SubmitSignatureParams, QuoteData } from '../types';

const initialState: MyQuoteState = {
  submitSignatureIsLoading: false,
  submitSignatureIsSuccess: false,
  submitSignatureHasError: false,

  getQuoteIsLoading: false,
  getQuoteData: null,
};

const myQuoteSlice = createSlice({
  name: 'myQuote',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    submitSignature(state, action: PayloadAction<SubmitSignatureParams>) {
      state.submitSignatureIsLoading = true;
      state.submitSignatureIsSuccess = false;
      state.submitSignatureHasError = false;
    },
    submitSignatureIsSuccess(state) {
      state.submitSignatureIsLoading = false;
      state.submitSignatureIsSuccess = true;
    },
    submitSignatureHasError(state) {
      state.submitSignatureIsLoading = false;
      state.submitSignatureHasError = true;
    },
    resetSubmitSignature(state) {
      state.submitSignatureIsLoading = false;
      state.submitSignatureIsSuccess = false;
      state.submitSignatureHasError = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getQuote(state, action: PayloadAction<number>) {
      state.getQuoteIsLoading = true;
    },
    getQuoteSuccess(state, action: PayloadAction<QuoteData>) {
      state.getQuoteIsLoading = false;
      state.getQuoteData = action.payload;
    },
    getQuoteFailure(state) {
      state.getQuoteIsLoading = false;
    },
  },
});

// Actions
export const myQuoteActions = myQuoteSlice.actions;

// Reducer
const myQuoteReducer = myQuoteSlice.reducer;
export default myQuoteReducer;
