import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { StyleRoomAvailabilityResponse, StyleRoomAvailabilityParams } from '../../types';
import { bookStyleRoomActions } from '../bookStyleRoomSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';

async function callApi(props: StyleRoomAvailabilityParams) {
  const queryParam = {
    idClient: props?.idClient,
    idLot: props?.idLot,
  };
  return ApiRequest(Method.GET, ENDPOINTS.styleRoomAvailability, undefined, queryParam);
}

export default function* callFetchStyleRoomAvailabilitySaga({ payload }: PayloadAction<StyleRoomAvailabilityParams>) {
  try {
    const availabilityResponse: StyleRoomAvailabilityResponse = yield call(callApi, payload);
    yield put(bookStyleRoomActions.fetchStyleRoomAvailabilitySuccess(availabilityResponse));
  } catch (error: unknown) {
    console.error('callFetchStyleRoomAvailabilitySaga', error);
    yield put(bookStyleRoomActions.fetchStyleRoomAvailabilityFailure());
  }
}
