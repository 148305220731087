export default function getNumberOrNull(input: string | number | undefined | null) {
  if (input === undefined || input === null) {
    return null;
  }

  try {
    const number = parseInt(input.toString());
    if (number == -1) {
      return null;
    }
    return isNaN(number) ? null : number;
  } catch (e) {
    return null;
  }
}
