import * as React from 'react';
import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import {
  NESTED_ROUTE_SELECTION_SUMMARY,
  ROUTE_FLOOR_SELECTION,
  ROUTE_FLOORS,
  ROUTE_GENERIC_FLOW,
  ROUTE_LOGIN,
  ROUTE_MAIN_MENU,
  ROUTE_MAKE_APPOINTMENT,
  ROUTE_MY_QUOTE,
  ROUTE_TOTAL_PRICE_OVERVIEW,
  GENERIC_FLOW_PAGE,
  ROUTE_FLOOR_PREVIEW,
} from './constants';
import LoginPage from '../features/login/pages/LoginPage';
import FloorSelectionPage from '../features/styleSelection/pages/FloorSelectionPage';
import MainMenuPage from '../features/mainMenu/pages/MainMenuPage';
import GuestWrapper from './GuestWrapper';
import AuthorizedWrapper from './AuthorizedWrapper';
import SummaryOfSelectionPage from '../features/styleSelection/pages/SummaryOfSelectionPage';
import TotalPriceOverviewPage from '../features/totalPriceOverview/pages/TotalPriceOverviewPage';
import MyQuotePage from '../features/myQuote/pages/MyQuotePage';
import BookStyleRoomPage from '../features/bookStyleRoom/pages/BookStyleRoomPage';
import GenericFlowPage from '../features/genericFlow/pages/GenericFlowPage';
import FloorPreviewPage from '../features/styleSelection/pages/FloorPreviewPage';

export default function RootRouter() {
  const location = useLocation();

  useEffect(() => {
    const mainBody = document.querySelector('main');
    mainBody?.scrollTo(0, 0);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route
        path={ROUTE_LOGIN}
        element={
          <GuestWrapper>
            <LoginPage />
          </GuestWrapper>
        }
      />
      <Route
        path={ROUTE_MAIN_MENU}
        element={
          <AuthorizedWrapper>
            <MainMenuPage />
          </AuthorizedWrapper>
        }
      />
      <Route
        path={ROUTE_GENERIC_FLOW}
        element={
          <AuthorizedWrapper>
            <GenericFlowPage />
          </AuthorizedWrapper>
        }
      />
      <Route
        path={ROUTE_MY_QUOTE}
        element={
          <AuthorizedWrapper>
            <MyQuotePage />
          </AuthorizedWrapper>
        }
      />
      <Route
        path={ROUTE_FLOOR_SELECTION}
        element={
          <AuthorizedWrapper>
            <FloorSelectionPage />
          </AuthorizedWrapper>
        }
      />

      <Route
        path={ROUTE_MAKE_APPOINTMENT}
        element={
          <AuthorizedWrapper>
            <BookStyleRoomPage />
          </AuthorizedWrapper>
        }
      />
      <Route
        path={ROUTE_FLOORS}
        element={
          <AuthorizedWrapper>
            <Outlet />
          </AuthorizedWrapper>
        }
      >
        <Route path=":floor" element={<Outlet />}>
          <Route path={ROUTE_FLOOR_PREVIEW} element={<FloorPreviewPage />} />
          <Route path={GENERIC_FLOW_PAGE} element={<GenericFlowPage />} />
          <Route path={NESTED_ROUTE_SELECTION_SUMMARY} element={<SummaryOfSelectionPage />} />
        </Route>
      </Route>
      <Route
        path={ROUTE_TOTAL_PRICE_OVERVIEW}
        element={
          <AuthorizedWrapper>
            <TotalPriceOverviewPage />
          </AuthorizedWrapper>
        }
      />
      <Route path="/" element={<Navigate to={ROUTE_LOGIN} replace />} />
      <Route path="*" element={<h1>Not found</h1>} />
    </Routes>
  );
}
