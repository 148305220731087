import { call, put } from 'redux-saga/effects';
import { Method } from '../../../../api/types';
import { FetchMaterialsToSelectParam, ProductGroup } from '../../types';
import { genericFlowActions } from '../genericFlowSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { PayloadAction } from '@reduxjs/toolkit';

async function callApi(props: FetchMaterialsToSelectParam) {
  const queryParam = {
    ClientId: props.ClientId,
    Floor: props.Floor,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getMaterialsToSelect, undefined, queryParam);
}

export default function* callFetchMaterialToSelectSaga({ payload }: PayloadAction<FetchMaterialsToSelectParam>) {
  try {
    const materialsToSelect: ProductGroup[] = yield call(callApi, payload);
    yield put(genericFlowActions.getMaterialsToSelectSuccess(materialsToSelect));
  } catch (error: unknown) {
    console.error('callFetchMaterialToSelectSaga', error);
    yield put(genericFlowActions.getMaterialsToSelectFailure());
  }
}
