import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS } from '../../../../api/constants';
import { DownloadBrochureResponse, FetchDownloadBrochure } from '../../types';
import { styleSelectionActions } from '../styleSelectionSlice';

async function callApi(productId: number) {
  const queryParam = {
    productId: productId,
  };
  return ApiRequest(Method.GET, ENDPOINTS.productBrochure, undefined, queryParam);
}

export default function* callFetchDownloadBrochureSaga({ payload }: PayloadAction<FetchDownloadBrochure>) {
  try {
    const response: DownloadBrochureResponse = yield call(callApi, payload.productId);
    // Creating new object of PDF file
    const fileURL = `data:application/pdf;base64,${response.brochure}`;
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = response.brochureName ?? 'Brochure.pdf';
    alink.click();
  } catch (error: unknown) {
    console.error('callFetchDownloadBrochureSaga', error);
  } finally {
    yield put(styleSelectionActions.fetchDownloadBrochureLoaded());
  }
}
