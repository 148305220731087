import { spawn } from 'redux-saga/effects';
import mainMenuDetailsSaga from '../features/mainMenu/store/mainMenuSaga';
import loginSaga from '../features/login/store/loginSaga';
import styleSelectionSaga from '../features/styleSelection/store/styleSelectionSaga';
import totalPriceOverviewSaga from '../features/totalPriceOverview/store/totalPriceOverviewSaga';
import myQuoteSaga from '../features/myQuote/store/myQuoteSaga';
import bookStyleRoomSaga from '../features/bookStyleRoom/store/bookStyleRoomSaga';
import genericFlowSaga from '../features/genericFlow/store/genericFlowSaga';

export default function* rootSaga() {
  yield spawn(mainMenuDetailsSaga);
  yield spawn(loginSaga);
  yield spawn(styleSelectionSaga);
  yield spawn(totalPriceOverviewSaga);
  yield spawn(myQuoteSaga);
  yield spawn(bookStyleRoomSaga);
  yield spawn(genericFlowSaga);
}
