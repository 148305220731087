import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Button, Skeleton, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import IconButton from '@mui/material/IconButton';
import { Colors } from '../../../utils/constant';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { CancelButton } from '../BasicComoponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types';

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  background-color: ${Colors.White};
`;

const TopContainer = styled.div<{ $bgColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 22px;
  background-color: ${(props) => props.$bgColor};
`;

const StyledTitle = styled.h2`
  color: ${Colors.White};
  font-family: NeulisAlt, sans-serif;
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  line-height: 30px;
`;

const StyledDescription = styled.p`
  color: ${Colors.White};
  font-size: 16px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 10px;
`;

const SubTitleText = styled.p`
  color: ${Colors.GreyPink};
  font-size: 14px;
  font-weight: 700;
  line-height: 110%;
`;

const ValueText = styled.p<{ $bgColor: string }>`
  color: ${(props) => props.$bgColor};
  font-size: 14px;
  font-weight: 700;
  line-height: 110%;
  margin-top: 4px;
`;

const PriceSubTitleText = styled.p`
  color: ${Colors.BeetleGreen};
  font-size: 14px;
  font-weight: 700;
  line-height: 110%;
`;

const PriceValueText = styled.p`
  color: ${Colors.CharcoalGrey};
  font-size: 14px;
  font-weight: 400;
`;

const SubTitleContainer = styled.div`
  margin: 30px 22px 0;
`;

const PriceContainer = styled.div`
  margin: 30px 22px 0;
`;

const ContinueButton = styled(Button)<{ $selected: boolean; $buttonDiable: boolean; $buttonColor: string }>`
  padding: 10px !important;
  font-size: ${({ $selected }) => ($selected ? '14px' : '16px')} !important;
  border-radius: 2px !important;
  background: ${({ $buttonColor }) => $buttonColor} !important;
  color: ${Colors.White} !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  opacity: ${({ $buttonDiable }) => ($buttonDiable ? 0.6 : 1)} !important;
  height: 40px !important;
  width: 140px !important;
`;

const InnerPriceContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 4px;
`;

const FloorType = styled(Typography)`
  font-size: 20px !important;
  font-weight: 700 !important;
  color: ${Colors.White};
  line-height: 160% !important;
  margin-top: 10px !important;
  letter-spacing: 0.8px;
`;

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.White,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    border: `1px solid ${Colors.CharcoalGrey}`,
  },
});

interface FloorMaterialSelectionRightContainerProps {
  title: string;
  subTitle: string;
  handleClose: () => void;
  handleContinueClick: () => void;
  handleGoBack?: () => void;
  continueBtnDisabled?: boolean;
  price: string;
  hidePrice?: boolean;
  tooltipText?: string | null;
  description: string;
  name: string;
  bgColor: string;
  isMaterialSelected?: boolean;
}

export default function OptionSelectorRightContainer({
  title,
  subTitle,
  handleClose,
  handleContinueClick,
  handleGoBack,
  continueBtnDisabled,
  price,
  hidePrice = false,
  tooltipText,
  description,
  name,
  bgColor,
  isMaterialSelected,
}: FloorMaterialSelectionRightContainerProps) {
  const slectedFloorType = useSelector((state: RootState) => state.genericFlow.slectedFloorType);

  return (
    <RightContainer>
      <TopContainer $bgColor={bgColor}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
          <StyledTitle>{title}</StyledTitle>
          <IconButton onClick={handleClose} sx={{ padding: 0, marginLeft: '10px' }}>
            <CloseIcon stroke={Colors.White} width={24} />
          </IconButton>
        </Box>
        <FloorType>{slectedFloorType}</FloorType>
        <StyledDescription>{subTitle}</StyledDescription>
      </TopContainer>
      <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'space-between'}>
        <Box>
          <SubTitleContainer>
            <SubTitleText>{description}</SubTitleText>
            <ValueText $bgColor={bgColor}>{name}</ValueText>
          </SubTitleContainer>
          {!hidePrice && (
            <PriceContainer>
              <PriceSubTitleText>Prijs</PriceSubTitleText>
              <InnerPriceContainer>
                <PriceValueText>{price}</PriceValueText>
                {tooltipText && (
                  <CustomTooltip arrow enterTouchDelay={0} placement="right" title={tooltipText}>
                    <InfoOutlinedIcon fontSize={'inherit'} sx={{ marginLeft: '4px', color: Colors.CharcoalGrey }} />
                  </CustomTooltip>
                )}
              </InnerPriceContainer>
            </PriceContainer>
          )}
        </Box>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '22px' }}>
          <CancelButton onClick={handleGoBack}>Ga terug</CancelButton>
          <CustomTooltip
            arrow
            enterTouchDelay={0}
            placement="top"
            title={
              continueBtnDisabled
                ? 'Het is verplicht om een kleur te kiezen. Deze kleur kan later worden gewijzigd.'
                : ''
            }
          >
            <ContinueButton
              $selected={isMaterialSelected ? true : false}
              $buttonDiable={!!continueBtnDisabled}
              $buttonColor={bgColor}
              onClick={() => {
                if (!continueBtnDisabled) {
                  handleContinueClick();
                }
              }}
            >
              {/* {isMaterialSelected ? 'Ga verder' : 'Selecteer kleur'} */}
              Volgende
            </ContinueButton>
          </CustomTooltip>
        </div>
      </Box>
    </RightContainer>
  );
}

export function OptionSelectorRightContainerSkeleton({ bgColor }: { bgColor: string }) {
  return (
    <RightContainer>
      <TopContainer $bgColor={bgColor}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
          <Skeleton variant="rectangular" sx={{ width: '100%', height: '30px' }} />
          <Skeleton variant="rectangular" sx={{ width: '24px', height: '24px', marginLeft: '10px' }} />
        </Box>
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '76px', marginTop: '14px' }} />
      </TopContainer>
      <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'space-between'}>
        <Box>
          <SubTitleContainer>
            <Skeleton variant="rectangular" sx={{ width: '50%', height: '15px' }} />
            <Skeleton variant="rectangular" sx={{ width: '50%', height: '20px', marginTop: '4px' }} />
          </SubTitleContainer>
          <PriceContainer>
            <Skeleton variant="rectangular" sx={{ width: '50%', height: '15px' }} />
            <Skeleton variant="rectangular" sx={{ width: '50%', height: '20px', marginTop: '4px' }} />
          </PriceContainer>
        </Box>
        <Skeleton
          variant="rectangular"
          sx={{ width: '125px', height: '60px', margin: '22px', alignSelf: 'flex-end' }}
        />
      </Box>
    </RightContainer>
  );
}
