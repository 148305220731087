import CustomDialog from '../../../shared/components/CustomDialog';
import { SignupPayload } from '../types';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { StyledForm } from '../../../shared/components/StyledForm';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import TextInputFieldFilled from '../../../shared/components/TextInputFieldFilled';
import { loginActions } from '../store/loginSlice';
import { signupIsLoadingSelector, signupIsSuccessSelector } from '../store/loginSelectors';
import { Colors } from '../../../utils/constant';

const StyledDescription = styled.p`
  color: ${Colors.White};
  font-size: 16px;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.64px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

interface CreateAccountModalProps {
  openModal: boolean;
  closeModal: () => void;
}

export default function CreateAccountModal(props: CreateAccountModalProps) {
  const { openModal, closeModal } = props;
  const dispatch = useAppDispatch();
  const signupIsSuccess = useAppSelector(signupIsSuccessSelector);
  const signupIsLoading = useAppSelector(signupIsLoadingSelector);

  useEffect(() => {
    if (signupIsSuccess) {
      dispatch(loginActions.resetSignup());
      closeModal();
    }
  }, [closeModal, dispatch, signupIsSuccess]);

  if (!openModal) return null;

  function handleSubmit(values: SignupPayload) {
    dispatch(loginActions.fetchSignup(values));
  }

  const initialValues: SignupPayload = {
    name: '',
    email: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Naam is vereist'),
    email: Yup.string().email('E-mail moet een geldig e-mailadres zijn').required('E-mail is vereist'),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, submitForm }) => {
        return (
          <StyledForm>
            <CustomDialog
              open={openModal}
              handleClose={closeModal}
              title={'Problemen met inloggen?'}
              cancelButtonText={'Annuleren'}
              handleCancelButtonPress={closeModal}
              primaryButtonText={'Meld je aan'}
              primaryButtonDisabled={!isValid || !dirty}
              primaryButtonIsLoading={signupIsLoading}
              primaryButtonWidth={'130px'}
              handlePrimaryButtonPress={submitForm}
              showCloseButton={true}
            >
              <Grid container rowSpacing={2} mb={2}>
                <Grid item xs={12}>
                  <StyledDescription>
                    Voer het e-mailadres in dat bij ons bekend is en je ontvangt de inloggegevens per e-mail.
                  </StyledDescription>
                </Grid>
                <Grid item xs={12}>
                  <TextInputFieldFilled
                    label={'Naam'}
                    name="name"
                    required
                    markAsRequired
                    placeholder={'Volledige naam van de gebruiker...'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInputFieldFilled
                    label={'E-mail'}
                    name="email"
                    required
                    markAsRequired
                    placeholder={'E-mail van de gebruiker...'}
                  />
                </Grid>
              </Grid>
            </CustomDialog>
          </StyledForm>
        );
      }}
    </Formik>
  );
}
