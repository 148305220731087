export const DaysOfTheWeek: Record<number, string> = {
  0: 'Zondag', // sunday
  1: 'Maandag', // monday
  2: 'Dinsdag', // tuesday
  3: 'Woensdag', // wednesday
  4: 'Donderdag', //thursday
  5: 'Vrijdag', // friday
  6: 'Zaterdag', // saturday
};

export const Months: Record<number, string> = {
  0: 'januari', // January
  1: 'februari', // February
  2: 'maart', // March
  3: 'april', // April
  4: 'mei', // May
  5: 'juni', // June
  6: 'juli', // July
  7: 'augustus', // August
  8: 'september', // September
  9: 'oktober', // October
  10: 'november', // November
  11: 'december', // December
};
