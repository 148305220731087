import { FloorColors } from './constant';

export function getReadableFloorName(floor?: number): string {
  switch (floor) {
    case 4:
      return 'Vierde verdieping';
    case 3:
      return 'Derde verdieping';
    case 2:
      return 'Tweede verdieping';
    case 1:
      return 'Eerste verdieping';
    case 0:
    default:
      return 'Begane grond';
  }
}

export function getFloorColor(floor?: number): string {
  switch (floor) {
    case 4:
      return FloorColors[4];
    case 3:
      return FloorColors[3];
    case 2:
      return FloorColors[2];
    case 1:
      return FloorColors[1];
    case 0:
    default:
      return FloorColors[0];
  }
}

export function getCurrentFloor(floor?: string): number {
  switch (floor) {
    case '5':
      return 4;
    case '4':
      return 3;
    case '3':
      return 2;
    case '2':
      return 1;
    case '1':
    default:
      return 0;
  }
}
