import React, { useEffect, useState } from 'react';
import PageHeader from '../../../shared/components/pageHeader/PageHeader';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Theme, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTE_FLOOR_SELECTION, ROUTE_MAIN_MENU } from '../../../routes/constants';
import { getCurrentFloor, getReadableFloorName } from '../../../utils/getReadableFloorName';
import { Colors } from '../../../utils/constant';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import {
  cancelSelectionForFloorIsLoadingSelector,
  cancelSelectionForFloorIsSuccessSelector,
  editChoosenStylesIsLoadingSelector,
  editChoosenStylesIsSuccessSelector,
  selectedCurtainDecorationSelector,
  // selectedFloorColorSelector,
  selectedFloorDecorationSelector,
  // selectedWindowColorSelector,
  selectedWindowDecorationSelector,
} from '../store/styleSelectionSelectors';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import priceFormatter, { isNumber } from '../../../utils/priceFormatter';
import { ReactComponent as HomeHashTagIcon } from '../../../assets/images/homeHashTag.svg';
import { styleSelectionActions } from '../store/styleSelectionSlice';
import { clientIdSelector } from '../../login/store/loginSelectors';
import { CancelButton, ConfirmButton, ErrorMessageWrapper } from '../../../shared/components/BasicComoponent';
import { idLotSelector, selectedMaterialsSelector } from '../../mainMenu/store/mainMenuSelectors';
import WhiteBoxPageWrapper from '../../../shared/WhiteBoxPageWrapper';

const DecorationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;

const EmptyMessage = styled.p`
  font-size: 16px;
  text-align: center;
  margin: 0 48px;
  color: black;
`;

const DecorationTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
`;

const Separator = styled.div`
  background-color: ${Colors.Platinum};
  height: 1px;
  margin-top: 8px;
  @media screen and (max-width: 599px) {
    display: none;
  }
`;

const DecorationFloor = styled.p`
  color: ${Colors.CharcoalGrey};
  font-family: NeulisAlt, sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  margin-left: -2px;
  margin-top: 32px;
  @media screen and (max-width: 599px) {
    font-weight: normal;
  }
`;

const DecorationTotal = styled.p`
  color: ${Colors.CharcoalGrey};
  font-family: NeulisAlt, sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  margin-left: -2px;
  @media screen and (max-width: 599px) {
    font-weight: normal;
  }
`;

const DecorationMaterial = styled.p`
  color: ${Colors.CharcoalGrey};
  font-size: 16px;
  font-weight: 300;
`;

const DecorationInstallation = styled.p`
  color: ${Colors.CharcoalGrey};
  font-size: 16px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 8px;
  @media screen and (max-width: 599px) {
    margin-top: 0;
  }
`;

const DecorationPrice = styled.p`
  color: ${Colors.BeetleGreen};
  font-style: italic;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
`;

const DecorationTotalPrice = styled.p`
  color: ${Colors.BeetleGreen};
  font-style: italic;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
`;

const StyledTitle = styled.h2`
  font-family: NeulisAlt, sans-serif;
  font-style: italic;
  font-weight: 700;
  color: ${Colors.CharcoalGrey};
  font-size: 40px;
  line-height: normal;
`;

const StyledSubTitle = styled.h3`
  color: ${Colors.CharcoalGrey};
  font-size: 32px;
  font-style: italic;
  line-height: normal;
  margin-top: 20px;
  font-family: NeulisAlt, sans-serif;
  font-weight: 700;
`;

const StyledDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 4px;
  color: ${Colors.CharcoalGrey};
`;

export default function SummaryOfSelectionPage() {
  const dispatch = useAppDispatch();
  const { floor } = useParams();
  const currentFloorIndex = getCurrentFloor(floor);
  const currentFloorNumber = currentFloorIndex + 1;
  const readableFloor = getReadableFloorName(currentFloorIndex);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const idLot = useAppSelector(idLotSelector);
  const clientId = useAppSelector(clientIdSelector);
  const selectedMaterials = useAppSelector(selectedMaterialsSelector);
  const selectedFloorDecoration = useAppSelector(selectedFloorDecorationSelector);
  // const selectedFloorColor = useAppSelector(selectedFloorColorSelector);
  const selectedWindowDecoration = useAppSelector(selectedWindowDecorationSelector);
  const selectedCurtainDecoration = useAppSelector(selectedCurtainDecorationSelector);
  // const selectedWindowColor = useAppSelector(selectedWindowColorSelector);
  const editChoosenStylesIsSuccess = useAppSelector(editChoosenStylesIsSuccessSelector);
  const editChoosenStylesIsLoading = useAppSelector(editChoosenStylesIsLoadingSelector);
  const cancelSelectionForFloorIsSuccess = useAppSelector(cancelSelectionForFloorIsSuccessSelector);
  const cancelSelectionForFloorIsLoading = useAppSelector(cancelSelectionForFloorIsLoadingSelector);

  useEffect(() => {
    if (editChoosenStylesIsSuccess) {
      dispatch(styleSelectionActions.resetEditChoosenStyles());
      navigate(ROUTE_FLOOR_SELECTION, { replace: true });
    }
  }, [editChoosenStylesIsSuccess, dispatch, navigate]);

  useEffect(() => {
    if (cancelSelectionForFloorIsSuccess) {
      dispatch(styleSelectionActions.resetCancelSelectionForFloor());
      navigate(ROUTE_FLOOR_SELECTION, { replace: true });
    }
  }, [cancelSelectionForFloorIsSuccess, dispatch, navigate]);

  function handleClose() {
    navigate(ROUTE_FLOOR_SELECTION);
  }

  function handleCancelSavedData() {
    const existingSelections = selectedMaterials?.filter((i) => i.floors === currentFloorNumber) || [];
    if (existingSelections.length) {
      dispatch(
        styleSelectionActions.cancelSelectionForFloor({
          clientId,
          idLot,
          floor: currentFloorNumber,
        }),
      );
    } else {
      navigate(ROUTE_MAIN_MENU, { replace: true });
    }
  }

  function handleConfirmClick() {
    const selections = [];
    if (selectedFloorDecoration) {
      selections.push({
        idClient: clientId,
        idProduct: selectedFloorDecoration.idProduct ?? -1,
        idTemplete: selectedFloorDecoration.idTemplete ?? -1,
        floors: currentFloorNumber,
        idMaterialColor: selectedFloorDecoration?.idMaterialColor ?? -1,
        idDefaultTemplate: selectedFloorDecoration.idDefaultTemplete ?? -1,
        idChoosenStyle: selectedFloorDecoration?.idChoosenStyle ?? -1,
        price: selectedFloorDecoration.price,
      });
    }
    if (selectedWindowDecoration) {
      selections.push({
        idClient: clientId,
        idProduct: selectedWindowDecoration.idProduct ?? -1,
        idTemplete: selectedWindowDecoration.idTemplete ?? -1,
        floors: currentFloorNumber,
        idMaterialColor: selectedWindowDecoration?.idMaterialColor ?? -1,
        idDefaultTemplate: selectedWindowDecoration.idDefaultTemplete ?? -1,
        idChoosenStyle: selectedWindowDecoration?.idChoosenStyle ?? -1,
        price: selectedWindowDecoration.price,
      });
    }
    if (selectedCurtainDecoration) {
      selections.push({
        idClient: clientId,
        idProduct: selectedCurtainDecoration.idProduct ?? -1,
        idTemplete: selectedCurtainDecoration.idTemplete ?? -1,
        floors: currentFloorNumber,
        idMaterialColor: selectedCurtainDecoration?.idMaterialColor ?? -1,
        idDefaultTemplate: selectedCurtainDecoration.idDefaultTemplete ?? -1,
        idChoosenStyle: selectedCurtainDecoration?.idChoosenStyle ?? -1,
        price: selectedCurtainDecoration.price,
      });
    }
    if (selections.length) {
      dispatch(styleSelectionActions.editChoosenStyles(selections));
    } else {
      navigate(ROUTE_FLOOR_SELECTION, { replace: true });
    }
  }

  const description =
    'Onderstaand vind je de prijsopgave voor producten voor deze verdieping. Zodra je alle verdiepingen hebt ingevuld, ga je naar het winkelmandje om een aanbieding te ontvangen voor alle gekozen producten.';
  const floorMaterial = selectedFloorDecoration?.description ?? 'N/A';
  const windowMaterial = selectedWindowDecoration?.description ?? 'N/A';
  const curtainMaterial = selectedCurtainDecoration?.description ?? 'N/A';

  let totalPrice;
  totalPrice =
    Number(selectedFloorDecoration?.price ?? 0) +
    Number(selectedWindowDecoration?.price ?? 0) +
    Number(selectedCurtainDecoration?.price ?? 0);
  if (
    (selectedFloorDecoration && !isNumber(selectedFloorDecoration?.price)) ||
    (selectedWindowDecoration && !isNumber(selectedWindowDecoration?.price)) ||
    (selectedCurtainDecoration && !isNumber(selectedCurtainDecoration?.price))
  ) {
    totalPrice = undefined;
  }

  const dataNotAvailable = !(selectedFloorDecoration || selectedWindowDecoration || selectedCurtainDecoration);

  return (
    <>
      <PageHeader
        background={isSmallScreen ? Colors.White : undefined}
        buttonText={'Home'}
        buttonIcon={<HomeHashTagIcon stroke={isSmallScreen ? Colors.CharcoalGrey : Colors.White} />}
        onButtonClick={handleClose}
        inverted={isSmallScreen}
      />
      <WhiteBoxPageWrapper>
        <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'column'}>
            {!isSmallScreen && (
              <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <StyledTitle>{readableFloor}</StyledTitle>
                <IconButton onClick={handleClose} sx={{ padding: 0, marginLeft: '10px' }}>
                  <CloseIcon stroke={Colors.CharcoalGrey} width={24} />
                </IconButton>
              </Box>
            )}
            <StyledSubTitle>Prijsopgave</StyledSubTitle>
            <StyledDescription>{description}</StyledDescription>

            {selectedFloorDecoration && (
              <>
                <DecorationFloor>{isSmallScreen ? 'Vloer' : 'Vloerbekleding'}</DecorationFloor>
                <DecorationContainer>
                  <DecorationMaterial>{floorMaterial}</DecorationMaterial>
                  <DecorationPrice>{priceFormatter(selectedFloorDecoration?.price)}</DecorationPrice>
                </DecorationContainer>
                <Separator />
              </>
            )}

            {selectedWindowDecoration && (
              <>
                <DecorationFloor>Raamdecoratie</DecorationFloor>
                <DecorationContainer>
                  <DecorationMaterial>{windowMaterial}</DecorationMaterial>
                  <DecorationPrice>{priceFormatter(selectedWindowDecoration?.price)}</DecorationPrice>
                </DecorationContainer>
                <Separator />
              </>
            )}

            {selectedCurtainDecoration && (
              <>
                <DecorationFloor>Gordijnen</DecorationFloor>
                <DecorationContainer>
                  <DecorationMaterial>{curtainMaterial}</DecorationMaterial>
                  <DecorationPrice>{priceFormatter(selectedCurtainDecoration?.price)}</DecorationPrice>
                </DecorationContainer>
                <Separator />
              </>
            )}

            {dataNotAvailable && (
              <ErrorMessageWrapper>
                <EmptyMessage>Er is geen materiaal geselecteerd.</EmptyMessage>
              </ErrorMessageWrapper>
            )}

            {!dataNotAvailable && (
              <DecorationTotalContainer>
                <DecorationTotal>Totaal:</DecorationTotal>
                <DecorationTotalPrice>{priceFormatter(totalPrice)}</DecorationTotalPrice>
              </DecorationTotalContainer>
            )}
            {!dataNotAvailable && <Separator />}
            {!dataNotAvailable && (
              <DecorationInstallation>
                {!isSmallScreen ? 'Inclusief installatie' : 'Incl. installatie'}
              </DecorationInstallation>
            )}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={isSmallScreen ? 'center' : 'center'}
            height={'58px'}
            columnGap={1}
            mt={'20px'}
          >
            <CancelButton
              onClick={handleCancelSavedData}
              disabled={dataNotAvailable || cancelSelectionForFloorIsLoading}
            >
              Annuleren
            </CancelButton>
            <ConfirmButton onClick={handleConfirmClick} disabled={dataNotAvailable || editChoosenStylesIsLoading}>
              Ga verder
            </ConfirmButton>
          </Box>
        </Box>
      </WhiteBoxPageWrapper>
    </>
  );
}
