import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { FetchTotalPriceOverviewParams, TotalPriceOverview } from '../../types';
import { totalPriceOverviewActions } from '../totalPriceOverviewSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';

async function callApi(props: FetchTotalPriceOverviewParams) {
  const queryParam = {
    clientId: props?.clientId,
    idLot: props?.idLot,
  };
  return ApiRequest(Method.GET, ENDPOINTS.totalPriceOverview, undefined, queryParam);
}

export default function* callFetchTotalPriceOverviewSaga({ payload }: PayloadAction<FetchTotalPriceOverviewParams>) {
  try {
    const priceOverview: TotalPriceOverview = yield call(callApi, payload);
    if (priceOverview.success) {
      yield put(totalPriceOverviewActions.fetchTotalPriceOverviewSuccess(priceOverview));
    } else {
      throw new Error('Not found');
    }
  } catch (error: unknown) {
    console.error('callFetchTotalPriceOverviewSaga', error);
    yield put(totalPriceOverviewActions.fetchTotalPriceOverviewFailure());
  }
}
