import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { Colors, ImageBaseUrl } from '../../../utils/constant';
import { CancelButton, ContinueButton, ImageLoader, Label, Value } from './Common';
import { GetFloorImageResponse } from '../types';

import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface FloorImageDetailsProps {
  floorImageIsLoading: boolean;
  floorImageData: GetFloorImageResponse | null;
  handleClose: () => void;
  handleContinue: () => void;
}

const FloorImageDetails: React.FC<FloorImageDetailsProps> = ({
  floorImageIsLoading,
  floorImageData,
  handleClose,
  handleContinue,
}) => {
  // Implement the component logic here

  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
        <IconButton onClick={() => zoomIn()}>
          <ZoomInIcon />
        </IconButton>
        <IconButton onClick={() => zoomOut()}>
          <ZoomOutIcon />
        </IconButton>
        <IconButton onClick={() => resetTransform()}>
          <RestartAltIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ backgroundColor: Colors.GreyPink, padding: '44px 32px 24px', height: 'fit-content' }}>
        <Typography
          sx={{
            color: Colors.White,
            fontFamily: 'NeulisAlt, sans-serif',
            fontSize: '32px',
            fontStyle: 'italic',
            fontWeight: '700',
            lineHeight: '30px',
          }}
        >
          Dit is jouw woning
        </Typography>
        <Typography
          sx={{
            color: Colors.White,
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '26px',
            marginTop: '10px',
          }}
        >
          {floorImageData?.description}
        </Typography>
      </Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        {floorImageIsLoading || !floorImageData ? (
          <ImageLoader>
            <Skeleton variant="rectangular" sx={{ width: '100%', height: '100%' }} />
          </ImageLoader>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: Colors.AshGrey,
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TransformWrapper>
              <Controls />
              <TransformComponent>
                <img src={`${ImageBaseUrl + floorImageData.floorImageBlobId}`} style={{ width: '100%' }} />
              </TransformComponent>
            </TransformWrapper>
          </Box>
        )}
      </Box>
      <Box sx={{ backgroundColor: Colors.White }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px 50px' }}>
          {floorImageData && (
            <>
              <Box>
                <Label>Vloeroppervlakte</Label>
                <Value>{`${floorImageData.totalSqMeter} m2`}</Value>
              </Box>
              <Box>
                <Label>Aantal ramen</Label>
                <Value>{floorImageData.numberOfWindows}</Value>
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '22px 60px',
            alignItems: 'center',
          }}
        >
          <CancelButton onClick={handleClose}>Ga terug</CancelButton>
          <ContinueButton onClick={handleContinue} isSmallScreen={true}>
            Ga verder
          </ContinueButton>
        </Box>
      </Box>
    </>
  );
};

export default FloorImageDetails;
