import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { SignupPayload, SignupResponse } from '../../types';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { loginActions } from '../loginSlice';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';

async function callApi(props: SignupPayload) {
  const payload = {
    name: props?.name,
    email: props?.email,
  };
  return ApiRequest(Method.POST, ENDPOINTS.signInEmail, undefined, undefined, payload);
}

export default function* callFetchSignupSaga({ payload }: PayloadAction<SignupPayload>) {
  try {
    const response: SignupResponse = yield call(callApi, payload);
    if (!response?.isMailSent) {
      throw response?.message;
    }
    yield put(loginActions.fetchSignupSuccess());
    SuccessToast('E-mail verstuurd', 'U ontvangt uw inloggegevens');
  } catch (error: unknown) {
    console.error('callFetchSignupSaga', error);
    yield put(loginActions.fetchSignupFailure());
    ErrorToast(undefined, error?.toString());
  }
}
