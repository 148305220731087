import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BookStyleRoomState,
  StyleRoomAvailabilityResponse,
  StyleRoomAvailabilityParams,
  BookAppointmentParams,
  BookAppointmentResponse,
} from '../types';

const initialState: BookStyleRoomState = {
  styleRoomAvailabilityIsLoading: false,
  styleRoomAvailabilities: null,
  styleRoomAvailabilityHasError: false,
  bookAppointmentIsLoading: false,
  bookAppointmentIsSuccess: false,
  bookAppointmentHasError: false,
};

const bookStyleRoomSlice = createSlice({
  name: 'bookStyleRoom',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchStyleRoomAvailability(state, action: PayloadAction<StyleRoomAvailabilityParams>) {
      state.styleRoomAvailabilityIsLoading = true;
      state.styleRoomAvailabilityHasError = false;
    },
    fetchStyleRoomAvailabilitySuccess(state, action: PayloadAction<StyleRoomAvailabilityResponse>) {
      state.styleRoomAvailabilityIsLoading = false;
      state.styleRoomAvailabilities = action.payload;
    },
    fetchStyleRoomAvailabilityFailure(state) {
      state.styleRoomAvailabilityIsLoading = false;
      state.styleRoomAvailabilityHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bookAppointment(state, action: PayloadAction<BookAppointmentParams>) {
      state.bookAppointmentIsLoading = true;
      state.bookAppointmentIsSuccess = false;
      state.bookAppointmentHasError = false;
    },
    bookAppointmentSuccess(state) {
      state.bookAppointmentIsLoading = false;
      state.bookAppointmentIsSuccess = true;
    },
    bookAppointmentFailure(state) {
      state.bookAppointmentIsLoading = false;
      state.bookAppointmentHasError = true;
    },
    resetBookAppointment(state) {
      state.bookAppointmentIsLoading = false;
      state.bookAppointmentIsSuccess = false;
      state.bookAppointmentHasError = false;
    },
  },
});

// Actions
export const bookStyleRoomActions = bookStyleRoomSlice.actions;

// Reducer
const bookStyleRoomReducer = bookStyleRoomSlice.reducer;
export default bookStyleRoomReducer;
