import { RootState } from '../../../store/types';

export const selectedFloorDecorationSelector = (state: RootState) =>
  state.styleSelectionReducer.selectedFloorDecoration;

export const selectedWindowDecorationSelector = (state: RootState) =>
  state.styleSelectionReducer.selectedWindowDecoration;
export const selectedCurtainDecorationSelector = (state: RootState) =>
  state.styleSelectionReducer.selectedCurtainDecoration;

export const editChoosenStylesIsLoadingSelector = (state: RootState) =>
  state.styleSelectionReducer.editChoosenStylesIsLoading;
export const editChoosenStylesIsSuccessSelector = (state: RootState) =>
  state.styleSelectionReducer.editChoosenStylesIsSuccess;

export const floorDecorationImageIsLoadingSelector = (state: RootState) =>
  state.styleSelectionReducer.floorDecorationImageIsLoading;
export const floorColorImageIsLoadingSelector = (state: RootState) =>
  state.styleSelectionReducer.floorColorImageIsLoading;
export const windowDecorationImageIsLoadingSelector = (state: RootState) =>
  state.styleSelectionReducer.windowDecorationImageIsLoading;
export const windowColorImageIsLoadingSelector = (state: RootState) =>
  state.styleSelectionReducer.windowColorImageIsLoading;
export const downloadBrochureIsLoadingSelector = (state: RootState) =>
  state.styleSelectionReducer.downloadBrochureIsLoading;

export const cancelSelectionForFloorIsLoadingSelector = (state: RootState) =>
  state.styleSelectionReducer.cancelSelectionForFloorIsLoading;
export const cancelSelectionForFloorIsSuccessSelector = (state: RootState) =>
  state.styleSelectionReducer.cancelSelectionForFloorIsSuccess;
