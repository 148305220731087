import { combineReducers } from 'redux';
import mainMenuDetailsReducer from '../features/mainMenu/store/mainMenuSlice';
import loginReducer from '../features/login/store/loginSlice';
import styleSelectionReducer from '../features/styleSelection/store/styleSelectionSlice';
import totalPriceOverviewReducer from '../features/totalPriceOverview/store/totalPriceOverviewSlice';
import myQuoteReducer from '../features/myQuote/store/myQuoteSlice';
import bookStyleRoomReducer from '../features/bookStyleRoom/store/bookStyleRoomSlice';
import genericFlowReducer from '../features/genericFlow/store/genericFlowSlice';

const rootReducer = combineReducers({
  mainMenuDetailsReducer,
  loginReducer,
  styleSelectionReducer,
  totalPriceOverviewReducer,
  myQuoteReducer,
  bookStyleRoomReducer,
  genericFlow: genericFlowReducer,
});

export default rootReducer;
