import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { BookAppointmentParams, BookAppointmentResponse } from '../../types';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { bookStyleRoomActions } from '../bookStyleRoomSlice';

async function callApi(props: BookAppointmentParams) {
  const pathParam = {
    idClient: props?.IdClient,
  };
  const payload = {
    comment: props?.comment,
    phoneNumber: props?.phoneNumber,
    bookedDate: props?.bookedDate,
    bookedTimeslots: props?.bookedTimeslots,
  };
  return ApiRequest(Method.POST, ENDPOINTS.bookAppointment, pathParam, undefined, payload);
}

export default function* callBookAppointmentSaga({ payload }: PayloadAction<BookAppointmentParams>) {
  try {
    // TODO
    const response: BookAppointmentResponse = yield call(callApi, payload);
    yield put(bookStyleRoomActions.bookAppointmentSuccess());
    SuccessToast('Succes!', 'Je hebt succesvol een tijdslot geboekt');
  } catch (error: unknown) {
    console.error('callBookAppointmentSaga', error);
    yield put(bookStyleRoomActions.bookAppointmentFailure());
    ErrorToast(undefined, error?.toString());
  }
}
