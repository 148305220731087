import { MobileViewOptionSelectorTopContainerSkeleton } from '../../../shared/components/OptionSelector/MobileViewOptionSelectorTopContainer';
import { Skeleton, Theme, useMediaQuery } from '@mui/material';
import { MobileViewOptionSelectorBottomContainerSkeleton } from '../../../shared/components/OptionSelector/MobileViewOptionSelectorBottomContainer';
import { OptionSelectorRightContainerSkeleton } from '../../../shared/components/OptionSelector/OptionSelectorRightContainer';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { InnerPageWrapper, SwiperContainer } from '../../../shared/components/BasicComoponent';
import { genericFlowActions } from '../../genericFlow/store/genericFlowSlice';
import { Colors } from '../../../utils/constant';
import { useDispatch } from 'react-redux';

const ContentWrapper = styled(InnerPageWrapper)`
  height: 600px;
  @media screen and (max-width: 599px) {
    height: 100%;
  }
`;

export default function DecorationSelectionSkeleton({ bgColor }: { bgColor: string }) {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (isSmallScreen) {
      dispatch(genericFlowActions.setMobilePageHeaderColor(Colors.GreyPink));
    }
    return () => {
      dispatch(genericFlowActions.setMobilePageHeaderColor(Colors.MarbleBlue));
    };
  }, [isSmallScreen]);

  return (
    <ContentWrapper>
      {isSmallScreen && <MobileViewOptionSelectorTopContainerSkeleton bgColor={bgColor} />}
      <SwiperContainer>
        <Skeleton variant="rectangular" sx={{ width: '100%', height: '100%' }} />
      </SwiperContainer>
      {isSmallScreen && <MobileViewOptionSelectorBottomContainerSkeleton />}
      {!isSmallScreen && <OptionSelectorRightContainerSkeleton bgColor={bgColor} />}
    </ContentWrapper>
  );
}
