import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CancelSelectionForTotalParams,
  FetchTotalPriceOverviewParams,
  SignQuoteParams,
  TotalPriceOverview,
  TotalPriceOverviewState,
} from '../types';

const initialState: TotalPriceOverviewState = {
  totalPriceOverviewIsLoading: false,
  totalPriceOverview: undefined,
  totalPriceOverviewHasError: false,

  signQuoteIsLoading: false,
  signQuoteIsSuccess: false,

  cancelSelectionForTotalIsLoading: false,
  cancelSelectionForTotalIsSuccess: false,
};

const totalPriceOverviewSlice = createSlice({
  name: 'totalPriceOverview',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchTotalPriceOverview(state, action: PayloadAction<FetchTotalPriceOverviewParams>) {
      state.totalPriceOverviewIsLoading = true;
      state.totalPriceOverviewHasError = false;
    },
    fetchTotalPriceOverviewSuccess(state, action: PayloadAction<TotalPriceOverview>) {
      state.totalPriceOverviewIsLoading = false;
      state.totalPriceOverview = action.payload;
    },
    fetchTotalPriceOverviewFailure(state) {
      state.totalPriceOverviewIsLoading = false;
      state.totalPriceOverview = undefined;
      state.totalPriceOverviewHasError = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    signQuote(state, action: PayloadAction<SignQuoteParams>) {
      state.signQuoteIsLoading = true;
      state.signQuoteIsSuccess = false;
    },
    signQuoteSuccess(state) {
      state.signQuoteIsLoading = false;
      state.signQuoteIsSuccess = true;
    },
    signQuoteFailure(state) {
      state.signQuoteIsLoading = false;
    },
    resetSignQuote(state) {
      state.signQuoteIsLoading = false;
      state.signQuoteIsSuccess = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cancelSelectionForTotal(state, action: PayloadAction<CancelSelectionForTotalParams>) {
      state.cancelSelectionForTotalIsLoading = true;
      state.cancelSelectionForTotalIsSuccess = false;
    },
    cancelSelectionForTotalSuccess(state) {
      state.cancelSelectionForTotalIsLoading = false;
      state.cancelSelectionForTotalIsSuccess = true;
    },
    cancelSelectionForTotalFailure(state) {
      state.cancelSelectionForTotalIsLoading = false;
    },
    resetCancelSelectionForTotal(state) {
      state.cancelSelectionForTotalIsLoading = false;
      state.cancelSelectionForTotalIsSuccess = false;
    },
  },
});

// Actions
export const totalPriceOverviewActions = totalPriceOverviewSlice.actions;

// Reducer
const totalPriceOverviewReducer = totalPriceOverviewSlice.reducer;
export default totalPriceOverviewReducer;
