import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Colors } from '../../../utils/constant';

export const ImageLoader = styled(Box)({
  width: '100%',
  height: '100%',
});

export const StyledImageContainer = styled('div')(({ src }: { src: string }) => ({
  backgroundColor: Colors.Boulder,
  background: `url(${src}) no-repeat`,
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  position: 'relative',
}));

export const Label = styled(Typography)({
  color: Colors.GreyPink,
  fontSize: '14px',
  fontWeight: 700,
});

export const Value = styled(Typography)({
  color: Colors.CharcoalGrey,
  fontSize: '14px',
  fontWeight: 400,
});

export const CancelButton = styled(Button)({
  padding: '16px',
  borderRadius: '2px',
  background: 'transparent',
  color: Colors.Black,
  fontSize: '16px',
  fontWeight: 700,
  textTransform: 'uppercase',
  height: '40px',
});

export const ContinueButton = styled(Button)(({ isSmallScreen }: { isSmallScreen: boolean }) => ({
  padding: '10px',
  fontSize: '16px',
  borderRadius: '2px',
  background: Colors.GreyPink,
  color: Colors.White,
  fontWeight: 700,
  textTransform: 'uppercase',
  width: isSmallScreen ? '125px' : '160px',
  height: isSmallScreen ? '60px' : '40px',
  '&:hover': {
    background: Colors.GreyPink,
    opacity: 0.8,
  },
}));
