/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { loginActions } from '../loginSlice';

async function callApi(clientId: number) {
  const queryParam = {
    IdClient: clientId,
  };
  return ApiRequest(Method.POST, ENDPOINTS.acceptTermsAndCondition, undefined, queryParam);
}

export default function* callTermsAndCondition({ payload }: PayloadAction<number>) {
  try {
    const resp: boolean = yield call(callApi, payload);
    yield put(loginActions.acceptTermsAndConditionSuccess());
  } catch (error: unknown) {
    console.error('callTermsAndCondition', error);
    yield put(loginActions.acceptTermsAndConditionFailure());
  }
}
