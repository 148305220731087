import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginPayload, LoginResponse, LoginState, SignupPayload, UserDetails } from '../types';

const initialState: LoginState = {
  loginIsLoading: false,
  tokenDetails: undefined,
  userDetails: undefined,
  loginHasError: undefined,
  token: undefined,
  activeLotsDetails: undefined,

  idClient: undefined,

  signupIsLoading: false,
  signupIsSuccess: false,
  signupHasError: false,

  acceptTermsAndConditionIsLoading: false,
  acceptTermsAndConditionIsSuccess: false,
  acceptTermsAndConditionHasError: false,

  clientInfoIsLoading: false,
  clientInfoIsSuccess: undefined,
  clientInfoHasError: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchLoginDetails(state, action: PayloadAction<LoginPayload>) {
      state.loginIsLoading = true;
      state.tokenDetails = undefined;
      state.userDetails = undefined;
      state.loginHasError = undefined;
      state.activeLotsDetails = undefined;
    },
    fetchLoginDetailsSuccess(state, action: PayloadAction<LoginResponse>) {
      state.loginIsLoading = false;
      state.tokenDetails = action.payload.tokenDetails;
      state.userDetails = action.payload.userDetails;
      state.activeLotsDetails = action.payload.activeLotsDetails;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    fetchLoginDetailsFailure(state, action: PayloadAction<string>) {
      state.loginIsLoading = false;
      state.loginHasError = action.payload;
    },
    resetLoginDetails(state) {
      state.loginIsLoading = false;
      state.tokenDetails = undefined;
      state.userDetails = undefined;
      state.loginHasError = undefined;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchSignup(state, action: PayloadAction<SignupPayload>) {
      state.signupIsLoading = true;
      state.signupIsSuccess = false;
      state.signupHasError = false;
    },
    fetchSignupSuccess(state) {
      state.signupIsLoading = false;
      state.signupIsSuccess = true;
    },
    fetchSignupFailure(state) {
      state.signupIsLoading = false;
      state.signupHasError = true;
    },
    resetSignup(state) {
      state.signupIsLoading = false;
      state.signupIsSuccess = false;
      state.signupHasError = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    acceptTermsAndCondition(state, action: PayloadAction<number>) {
      state.acceptTermsAndConditionIsLoading = true;
      state.acceptTermsAndConditionIsSuccess = false;
    },
    acceptTermsAndConditionSuccess(state) {
      state.acceptTermsAndConditionIsLoading = false;
      state.acceptTermsAndConditionIsSuccess = true;
    },
    acceptTermsAndConditionFailure(state) {
      state.acceptTermsAndConditionIsLoading = false;
      state.acceptTermsAndConditionHasError = true;
    },
    resetAcceptTermsAndCondition(state) {
      state.acceptTermsAndConditionIsLoading = false;
      state.acceptTermsAndConditionIsSuccess = false;
      state.acceptTermsAndConditionHasError = false;
    },

    setIdConst(state, action) {
      state.idClient = action.payload;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchClientInfo(state, action: PayloadAction<number>) {
      state.clientInfoIsLoading = true;
      state.clientInfoIsSuccess = undefined;
      state.clientInfoHasError = false;
    },
    fetchCleintInfoSuccess(state, action: PayloadAction<UserDetails>) {
      state.clientInfoIsLoading = false;
      state.clientInfoIsSuccess = action.payload;
    },
    fetchClientInfoFailure(state) {
      state.clientInfoIsLoading = false;
      state.clientInfoHasError = true;
    },
  },
});

// Actions
export const loginActions = loginSlice.actions;

// Reducer
const loginReducer = loginSlice.reducer;
export default loginReducer;
