import { ProductGroup, PrepareProductData } from '../types';

export const prepareProductData = (data: ProductGroup[]): PrepareProductData => {
  const result: PrepareProductData = {};

  data.forEach((productGroup) => {
    result[productGroup.id] = {
      productGroupId: productGroup.id,
      name: productGroup.name,
      swiperData: productGroup.product.map((product) => ({
        id: product.id,
        description: product.description,
        price: product.price,
        isDefaultPrice: product.isDefaultPrice,
        brochure: product.brochure,
        idImage: product.idImage,
        title: product.title,
        subtitle: product.subtitle,
        titledescription: product.titledescription,
        colors: product.colors,
        name: product.name,
      })),
    };
  });

  return result;
};

export const generateInitialSelection = (data: ProductGroup[], existingSelections: any) => {
  const result: any = {};

  existingSelections &&
    existingSelections.length > 0 &&
    existingSelections.forEach((selection: any) => {
      const idProductGroup = selection.idProductGroup;
      const idProduct = selection.selectedProduct?.idProduct || null;
      const idColor = selection.productColor?.idMaterialColor || null;
      if (data && data.length > 0) {
        const fullProductGroup = data.find((productGroup) => productGroup.id === idProductGroup);

        const product = fullProductGroup?.product.find((product) => product.id === idProduct) || null;
        const color = product?.colors?.find((color) => color.idMaterialColor === idColor) || null;

        result[idProductGroup] = {
          idTemplate: fullProductGroup?.idTemplete,
          idDefaultTemplete: fullProductGroup?.idDefaultTemplete,
          code: fullProductGroup?.code,
          idChoosenStyle: selection.selectedProduct?.idChoosenStyle || 0,
          product,
          color,
        };
      }
    });

  return result;
};
