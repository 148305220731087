import React from 'react';
import { Typography, Select, MenuItem, FormControl, styled } from '@mui/material';
import { Colors } from '../../utils/constant';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Root = styled('div')({
  marginBottom: 5,
  width: '100%',
  marginTop: 16,
});

const TextFieldLabel = styled(Typography)(({ labelColor }: { labelColor?: string }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: labelColor,
  marginBottom: 8,
}));

const SelectFieldStyles = styled(Select)({
  fontFamily: 'Montserrat !important',
  fontSize: '16px !important',
  fontWeight: 400,
  width: '100%',
  boxShadow: '0px 1px 4px rgba(39, 47, 53, 0.08)',
  padding: 'unset !important',
  height: 38,
  '&:control': {
    overFlow: 'scroll !important',
    maxHeight: '100px',
  },
  '&:hover': {
    boxShadow: '0px 1px 4px rgba(39, 47, 53, 0.08)',
    border: 'none',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'unset !important',
    borderWidth: 'unset',
  },
  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#EC4C29',
  },
  background: Colors.CoolGrey,
  color: Colors.MidGrey,
});

interface SelectFieldProps {
  label: string;
  selectedValue?: number;
  onChange?: (e: any) => void;
  names?: any;
  multiple: boolean;
  labelColor: string;
}

const SelectField: React.FC<SelectFieldProps> = ({ label, selectedValue, onChange, names, multiple, labelColor }) => {
  return (
    <Root>
      <TextFieldLabel labelColor={labelColor}>{label}</TextFieldLabel>
      <FormControl sx={{ width: '100%' }}>
        <SelectFieldStyles multiple={multiple} value={selectedValue} onChange={onChange} MenuProps={MenuProps}>
          {names &&
            names?.map((name: { name: string; idClient: number }, key: number) => (
              <MenuItem key={`select-item-${key}`} value={name?.idClient}>
                {name?.name}
              </MenuItem>
            ))}
        </SelectFieldStyles>
      </FormControl>
    </Root>
  );
};

export default SelectField;
