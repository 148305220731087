import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { GetFloorImageRequest, GetFloorImageResponse } from '../../types';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { styleSelectionActions } from '../styleSelectionSlice';

async function callApi(props: GetFloorImageRequest) {
  const queryParam = {
    IdClient: props.IdClient,
    Floor: props.floor,
  };
  return ApiRequest(Method.GET, ENDPOINTS.floorImage, undefined, queryParam);
}

export default function* callGetFloorImageSaga({ payload }: PayloadAction<GetFloorImageRequest>) {
  try {
    const response: GetFloorImageResponse = yield call(callApi, payload);
    yield put(styleSelectionActions.getFloorImageSuccess(response));
  } catch (error: unknown) {
    yield put(styleSelectionActions.getFloorImageFailure());
    ErrorToast(undefined, error?.toString());
  }
}
