import { RootState } from '../../../store/types';

export const totalPriceOverviewIsLoadingSelector = (state: RootState) =>
  state.totalPriceOverviewReducer.totalPriceOverviewIsLoading;
export const totalPriceOverviewSelector = (state: RootState) => state.totalPriceOverviewReducer.totalPriceOverview;
export const totalPriceOverviewHasErrorSelector = (state: RootState) =>
  state.totalPriceOverviewReducer.totalPriceOverviewHasError;

export const signQuoteIsLoadingSelector = (state: RootState) => state.totalPriceOverviewReducer.signQuoteIsLoading;
export const signQuoteIsSuccessSelector = (state: RootState) => state.totalPriceOverviewReducer.signQuoteIsSuccess;

export const cancelSelectionForTotalIsLoadingSelector = (state: RootState) =>
  state.totalPriceOverviewReducer.cancelSelectionForTotalIsLoading;
export const cancelSelectionForTotalIsSuccessSelector = (state: RootState) =>
  state.totalPriceOverviewReducer.cancelSelectionForTotalIsSuccess;
