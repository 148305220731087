import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // whitelist: ['loginReducer', 'mainMenuDetailsReducer', 'styleSelectionReducer'], // If the user reloads, previous selections should be there.
  blacklist: ['totalPriceOverviewReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: false,
    })
      // .concat(logger)
      .concat(sagaMiddleware),
});

export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export default store;
