import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RootRouter from './routes/RootRouter';
import backgroundImage from './assets/images/background.png';
import styled from 'styled-components';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const StyledContainer = styled.div`
  background-image: url(${backgroundImage});
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageOuterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  overflow: scroll;
`;

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1536px;
  display: flex;
  flex-direction: column;
`;

function App() {
  return (
    <StyledContainer>
      <PageOuterContainer>
        <PageContainer>
          <RootRouter />
        </PageContainer>
      </PageOuterContainer>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
      />
    </StyledContainer>
  );
}

export default App;
