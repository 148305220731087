import React, { useCallback, useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Colors } from '../../../utils/constant';
import PageHeader from '../../../shared/components/pageHeader/PageHeader';
import { Skeleton, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import { ROUTE_MAIN_MENU } from '../../../routes/constants';
import { ReactComponent as HomeHashTagIcon } from '../../../assets/images/homeHashTag.svg';
import { useNavigate } from 'react-router-dom';
import { CancelButton, ConfirmButton, ErrorMessageWrapper } from '../../../shared/components/BasicComoponent';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';
import { totalPriceOverviewActions } from '../../totalPriceOverview/store/totalPriceOverviewSlice';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import { clientIdSelector } from '../../login/store/loginSelectors';
import { idLotSelector } from '../../mainMenu/store/mainMenuSelectors';
import { myQuoteActions } from '../store/myQuoteSlice';
import { submitSignatureIsLoadingSelector, submitSignatureIsSuccessSelector } from '../store/myQuoteSelectors';
import {
  totalPriceOverviewIsLoadingSelector,
  totalPriceOverviewSelector,
} from '../../totalPriceOverview/store/totalPriceOverviewSelectors';
import priceFormatter from '../../../utils/priceFormatter';
import WhiteBoxPageWrapper from '../../../shared/WhiteBoxPageWrapper';

const StyledTitle = styled.h2`
  font-family: NeulisAlt, sans-serif;
  font-style: italic;
  font-weight: 700;
  color: ${Colors.CharcoalGrey};
  font-size: 40px;
  line-height: normal;
  @media screen and (max-width: 599px) {
    font-size: 32px;
  }
`;

const EmptyMessage = styled.p`
  font-size: 16px;
  text-align: center;
  margin: 0 48px;
  color: black;
`;

const StyledDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 4px;
  margin-bottom: 32px;
  color: ${Colors.CharcoalGrey};
`;

const SignatureContainer = styled.div<{ width: number; height: number }>`
  border: 1px solid ${Colors.GreyNickel};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
`;

const ClearButton = styled.button`
  background: transparent;
  border: none;
  color: ${Colors.PastelGrey};
  position: absolute;
  top: -20px;
  right: 0;
  cursor: pointer;
`;

const DecorationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.Platinum};
  padding-bottom: 8px;
  @media screen and (max-width: 599px) {
    border-bottom: none;
    padding-bottom: 10px;
  }
`;

// const DecorationInstallation = styled.p`
//   color: ${Colors.CharcoalGrey};
//   font-size: 16px;
//   font-weight: 300;
//   line-height: normal;
//   letter-spacing: 0.64px;
//   margin-top: 8px;
//   @media screen and (max-width: 599px) {
//     margin-top: -10px;
//   }
// `;

// const CheckBoxLabel = styled.p`
//   color: ${Colors.CharcoalGrey};
//   font-size: 14px;
//   font-weight: 300;
//   line-height: 140%;
//   margin-left: 4px;
// `;

// const CheckBoxContainer = styled.div`
//   display: flex;
//   margin-top: 20px;
//   flex-direction: row;
//   align-items: flex-start;
//   @media screen and (max-width: 599px) {
//     margin-top: 32px;
//   }
// `;

// const ErrorText = styled.p`
//   color: ${Colors.ReddishOrange};
//   text-align: left;
//   margin-top: 2px;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 300;
//   line-height: 160%;
//   letter-spacing: 0.24px;
// `;

const DecorationTitle = styled.p`
  color: ${Colors.CharcoalGrey};
  font-family: NeulisAlt, sans-serif;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  margin-left: -2px;
`;

const SignaturePadTitle = styled.p`
  color: ${Colors.CharcoalGrey};
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin: 12px 0 8px;
  letter-spacing: 0.64px;
  @media screen and (max-width: 599px) {
    margin-top: 32px;
  }
`;

const DecorationPrice = styled.p`
  color: ${Colors.BeetleGreen};
  font-style: italic;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
`;

const DecorationTotalPrice = styled.p`
  color: ${Colors.BeetleGreen};
  font-style: italic;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
`;

function MyQuotePageSkeleton() {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between" mb={'30px'}>
        <Skeleton variant="rectangular" sx={{ width: '180px', height: '29px' }} />
        <Skeleton variant="rectangular" sx={{ width: '50px', height: '24px' }} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mb={'30px'}>
        <Skeleton variant="rectangular" sx={{ width: '180px', height: '29px' }} />
        <Skeleton variant="rectangular" sx={{ width: '50px', height: '24px' }} />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mb={'16px'}>
        <Skeleton variant="rectangular" sx={{ width: '180px', height: '29px' }} />
        <Skeleton variant="rectangular" sx={{ width: '60px', height: '29px' }} />
      </Box>
      <Skeleton variant="rectangular" sx={{ width: '150px', height: '20px' }} />
      <Skeleton variant="rectangular" sx={{ width: '60%', height: '20px', marginTop: '20px' }} />
      <Skeleton variant="rectangular" sx={{ width: '120px', height: '20px', marginTop: '12px', marginBottom: '8px' }} />
      <Skeleton variant="rectangular" sx={{ width: '60%', height: '90px' }} />
    </Box>
  );
}

interface DecorationPrice {
  floor: number | null;
  window: number | null;
  curtain: number | null;
}

export default function MyQuotePage() {
  const dispatch = useAppDispatch();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const clientId = useAppSelector(clientIdSelector);
  const idLot = useAppSelector(idLotSelector);
  const totalPriceOverview = useAppSelector(totalPriceOverviewSelector);
  const totalPriceOverviewIsLoading = useAppSelector(totalPriceOverviewIsLoadingSelector);
  const submitSignatureIsLoading = useAppSelector(submitSignatureIsLoadingSelector);
  const submitSignatureIsSuccess = useAppSelector(submitSignatureIsSuccessSelector);

  const [location, setLocation] = useState<string>('');

  const floorDecorations = (totalPriceOverview?.priceOverview || []).filter((i) => i.productGroupCode === 1);
  const windowDecorations = (totalPriceOverview?.priceOverview || []).filter((i) => i.productGroupCode === 2);
  const curtainDecorations = (totalPriceOverview?.priceOverview || []).filter((i) => i.productGroupCode === 3);
  const dataNotAvailable =
    floorDecorations.length === 0 && windowDecorations.length === 0 && curtainDecorations.length === 0;
  const totalPrice = totalPriceOverview?.totalPrice;
  const decorationPrice: DecorationPrice = (totalPriceOverview?.priceOverview || []).reduce(
    (acc: DecorationPrice, i) => {
      if (i.productGroupCode === 1 && acc.floor !== null) {
        if (i.price === null || i.price === undefined) {
          acc.floor = null;
        } else {
          acc.floor += i.price;
        }
      }
      if (i.productGroupCode === 2 && acc.window !== null) {
        if (i.price === null || i.price === undefined) {
          acc.window = null;
        } else {
          acc.window += i.price;
        }
      }
      if (i.productGroupCode === 3 && acc.curtain !== null) {
        if (i.price === null || i.price === undefined) {
          acc.curtain = null;
        } else {
          acc.curtain += i.price;
        }
      }
      return acc;
    },
    { floor: 0, window: 0, curtain: 0 },
  );

  const [signature, setSignature] = useState<SignatureCanvas | null>(null);
  const [signatureIsEmpty, setSignatureIsEmpty] = useState<boolean>(true);
  const [dynamicWindowWidth, setDynamicWindowWidth] = useState(window.innerWidth);
  const effectiveWidth = Math.min(window.innerWidth - 64, 450);
  const effectiveHeight = dynamicWindowWidth < 600 ? 180 : 90;

  useEffect(() => {
    const updateWindowDimensions = () => {
      setDynamicWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  useEffect(() => {
    dispatch(
      totalPriceOverviewActions.fetchTotalPriceOverview({
        clientId: clientId,
        idLot: idLot,
      }),
    );
  }, [clientId, dispatch, idLot]);

  const handleClearSignature = useCallback(() => {
    signature?.clear();
    setSignatureIsEmpty(true);
  }, [signature]);

  useEffect(() => {
    if (submitSignatureIsSuccess) {
      dispatch(myQuoteActions.resetSubmitSignature());
      handleClearSignature();
      navigate(ROUTE_MAIN_MENU);
    }
  }, [dispatch, handleClearSignature, navigate, submitSignatureIsSuccess]);

  function handleGoHome() {
    navigate(ROUTE_MAIN_MENU);
  }

  function handleConfirm() {
    if (signature && !signature?.isEmpty() && location !== '') {
      dispatch(
        myQuoteActions.submitSignature({
          id: clientId,
          fileName: `signature-${clientId}`,
          data: signature.getTrimmedCanvas().toDataURL('image/png').substring(22),
          location,
        }),
      );
    }
  }

  function handleCancel() {
    navigate(ROUTE_MAIN_MENU);
  }

  function handleClose() {
    navigate(ROUTE_MAIN_MENU);
  }

  return (
    <>
      <PageHeader
        background={isSmallScreen ? Colors.White : undefined}
        buttonText={'Home'}
        buttonIcon={<HomeHashTagIcon stroke={isSmallScreen ? Colors.CharcoalGrey : Colors.White} />}
        onButtonClick={handleGoHome}
        inverted={isSmallScreen}
      />
      <WhiteBoxPageWrapper>
        <Box display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'space-between'}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
              <StyledTitle>Prijsopgave</StyledTitle>
              {!isSmallScreen && (
                <IconButton onClick={handleClose} sx={{ padding: 0, marginLeft: '10px' }}>
                  <CloseIcon stroke={Colors.CharcoalGrey} width={24} />
                </IconButton>
              )}
            </Box>
            <StyledDescription>
              {isSmallScreen ? 'Dit is de totale prijs opgave' : 'Dit is de totale prijsopgave per producttype.'}
            </StyledDescription>

            {totalPriceOverviewIsLoading ? (
              <MyQuotePageSkeleton />
            ) : dataNotAvailable ? (
              <>
                <ErrorMessageWrapper>
                  <EmptyMessage>Er is geen materiaal geselecteerd.</EmptyMessage>
                </ErrorMessageWrapper>
              </>
            ) : (
              <>
                <Box display={'flex'} flexDirection={'column'} gap={'22px'}>
                  {floorDecorations.length > 0 && (
                    <DecorationContainer>
                      <DecorationTitle>Vloer</DecorationTitle>
                      <DecorationPrice>{priceFormatter(decorationPrice.floor)}</DecorationPrice>
                    </DecorationContainer>
                  )}
                  {windowDecorations.length > 0 && (
                    <DecorationContainer>
                      <DecorationTitle>Raamdecoratie</DecorationTitle>
                      <DecorationPrice>{priceFormatter(decorationPrice.window)}</DecorationPrice>
                    </DecorationContainer>
                  )}
                  {curtainDecorations.length > 0 && (
                    <DecorationContainer>
                      <DecorationTitle>Gordijnen</DecorationTitle>
                      <DecorationPrice>{priceFormatter(decorationPrice.curtain)}</DecorationPrice>
                    </DecorationContainer>
                  )}
                  {!dataNotAvailable && (
                    <DecorationContainer>
                      <DecorationTitle>Totaal:</DecorationTitle>
                      <DecorationTotalPrice>{priceFormatter(totalPrice)}</DecorationTotalPrice>
                    </DecorationContainer>
                  )}
                </Box>
                <SignaturePadTitle>Handtekening</SignaturePadTitle>
                <SignatureContainer width={effectiveWidth} height={effectiveHeight}>
                  <SignatureCanvas
                    canvasProps={{ width: effectiveWidth, height: effectiveHeight, className: 'sigCanvas' }}
                    onEnd={() => setSignatureIsEmpty(false)}
                    ref={(data) => setSignature(data)}
                  />
                  <ClearButton type={'button'} onClick={handleClearSignature}>
                    Clear
                  </ClearButton>
                </SignatureContainer>
                <Box sx={{ marginTop: '10px' }}>
                  <Typography sx={{ fontFamily: 'NeulisAlt, sans-serif' }}>Locatie</Typography>
                  <TextField
                    size="small"
                    onChange={(event) => {
                      setLocation(event.target.value);
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={isSmallScreen ? 'flex-end' : 'center'}
            height={'58px'}
            columnGap={1}
            mt={'20px'}
          >
            <CancelButton onClick={handleCancel} disabled={totalPriceOverviewIsLoading}>
              Annuleren
            </CancelButton>
            <ConfirmButton
              onClick={handleConfirm}
              disabled={submitSignatureIsLoading || signatureIsEmpty || totalPriceOverviewIsLoading || location === ''}
            >
              Bevestigen
            </ConfirmButton>
          </Box>
        </Box>
      </WhiteBoxPageWrapper>
    </>
  );
}
