/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { ActiveLotsResponse, LoginPayload, TokenResponse, UserDetailsResponse } from '../../types';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { loginActions } from '../loginSlice';
import store from '../../../../store/store';

async function callApi(props: LoginPayload) {
  const payload = {
    userEmail: props?.email,
    password: props?.password,
  };
  return ApiRequest(Method.POST, ENDPOINTS.token, undefined, undefined, payload);
}

async function callUserFetchApi() {
  return ApiRequest(Method.GET, ENDPOINTS.userDetails);
}

async function callLotsApi() {
  return ApiRequest(Method.GET, ENDPOINTS.ActiveLots);
}

export default function* callFetchLoginSaga({ payload }: PayloadAction<LoginPayload>) {
  try {
    const tokenResponse: TokenResponse = yield call(callApi, payload);
    if (!tokenResponse?.token) {
      // @ts-ignore
      throw tokenResponse?.response?.data || 'Something went wrong. Please try again later';
    }
    store.dispatch(loginActions.setToken(tokenResponse?.token));
    const userResponse: UserDetailsResponse = yield call(callUserFetchApi);
    if (!userResponse?.users?.idClient) {
      // @ts-ignore
      throw userResponse?.response?.data || 'Unauthorized user';
    }
    const lotsResponse: ActiveLotsResponse[] = yield call(callLotsApi);

    yield put(
      loginActions.fetchLoginDetailsSuccess({
        tokenDetails: tokenResponse,
        userDetails: userResponse,
        activeLotsDetails: lotsResponse,
      }),
    );
  } catch (error: unknown) {
    console.error('callFetchLoginSaga', error);
    yield put(loginActions.fetchLoginDetailsFailure(error?.toString() ?? ''));
  }
}
