import React from 'react';
import { Dialog, Box, Button } from '@mui/material';
import styled from 'styled-components';

const MainContainer = styled.div`
  padding: 20px;
`;

interface PopupProps {
  openModal: boolean;
  closeModal: () => void;
  clickOkButton: () => void;
}

export default function CancelationPopup(props: PopupProps) {
  const { openModal, closeModal, clickOkButton } = props;

  function handleOkClick() {
    clickOkButton();
    closeModal();
  }

  return (
    <Dialog open={openModal} onClose={closeModal}>
      <MainContainer>
        <p>Weet je zeker dat je winkelwagen wilt leegmaken</p>
        <Box mt={2} display={'flex'} justifyContent={'center'} gap={1}>
          <Button
            disableElevation
            variant="text"
            onClick={closeModal}
            sx={{
              '& .MuiButton-root': {
                fontSize: '12px',
              },
              height: '36px',
              padding: '2px 12px 0',
              fontWeight: 600,
              textTransform: 'uppercase',
            }}
          >
            <p style={{ color: '#4f4c4c' }}>annuleren</p>
          </Button>
          <Button
            disableElevation
            variant="contained"
            onClick={handleOkClick}
            sx={{
              '& .MuiButton-root': {
                backgroundColor: '#BD8890',
                fontSize: '12px',
              },
              height: '36px',
              padding: '2px 12px 0',
              fontWeight: 600,
              textTransform: 'uppercase',
              '&:disabled': {
                color: '#FFFFFE',
                backgroundColor: '#BD8890',
                opacity: 0.6,
              },
              '&:hover': {
                backgroundColor: '#BD8890',
              },
            }}
          >
            <p style={{ color: '#FFFFFE' }}>bevestigen</p>
          </Button>
        </Box>
      </MainContainer>
    </Dialog>
  );
}
