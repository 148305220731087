import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { QuoteData } from '../../types';
import { myQuoteActions } from '../myQuoteSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';

async function callApi(id: number) {
  const pathParam = {
    id: id,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getQuote, pathParam, undefined);
}

export default function* callFetchQuoteSaga({ payload }: PayloadAction<number>) {
  try {
    // TODO
    const Response: QuoteData = yield call(callApi, payload);
    yield put(myQuoteActions.getQuoteSuccess(Response));
  } catch (error: unknown) {
    console.error('callFetchQuoteSaga', error);
    yield put(myQuoteActions.getQuoteFailure());
  }
}
