export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const DEFAULT_PAGE_SIZE = 10;

export const ENDPOINTS = {
  // Client
  submitSignatureForSignQuote: '/api/client/{id}/signQuote',
  getQuote: '/api/Client/{id}/Quote',

  // MijnLogin
  login: '/api/mijnLogin',
  signInEmail: '/api/mijnLogin/signInEmail',
  token: '/jwtToken',
  userDetails: '/userDetail',
  acceptTermsAndCondition: '/api/MijnLogin/AgreePrivacyStatement',
  ActiveLots: '/ActiveLots',
  clientInfo: '/ClientInfo/{Id}',

  // MijnMainMenu
  mainMenu: '/api/mijnMainMenu',
  floorImage: '/api/mijnMainMenu/FloorImage',

  // MijnProduct
  productFloorColor: '/api/mijnProduct/color',
  productFloorColorImage: '/api/mijnProduct/color/image',
  productFloorDecoration: '/api/mijnProduct/floorDecoration',
  productFloorDecorationImage: '/api/mijnProduct/image',
  productBrochure: '/api/mijnProduct/brochure',
  productWindowColor: '/api/mijnProduct/color',
  productWindowColorImage: '/api/mijnProduct/color/image',
  productWindowDecoration: '/api/mijnProduct/windowAndCurtainDecoration',
  productWindowDecorationImage: '/api/mijnProduct/image',
  productEditChoosenStyles: '/api/mijnProduct/choosenStyles',
  totalPriceOverview: '/api/mijnProduct/priceOverview',
  signQuote: '/api/mijnProduct/signQuote',
  cancelSelectionForFloor: '/api/mijnProduct/cancelSelectionForFloor',
  cancelSelectionForTotal: '/api/mijnProduct/cancelSelectionForTotal',
  styleRoomAvailability: '/api/mijnProduct/styleRoomAvailability',
  bookAppointment: '/api/mijnProduct/{idClient}/bookAppointment',

  //genericFlow
  getMaterialsToSelect: '/api/MijnProduct/MaterialToSelect',
  getExistingSelections: '/api/MijnMainMenu/ChoosenMaterials',
};
