import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { CancelSelectionForFloorParams, CancelSelectionForFloorResponse } from '../../types';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { styleSelectionActions } from '../styleSelectionSlice';

async function callApi(props: CancelSelectionForFloorParams) {
  const queryParam = {
    clientId: props?.clientId,
    idLot: props?.idLot,
    floor: props?.floor,
  };
  return ApiRequest(Method.GET, ENDPOINTS.cancelSelectionForFloor, undefined, queryParam);
}

export default function* callCancelSelectionForFloorSaga({ payload }: PayloadAction<CancelSelectionForFloorParams>) {
  try {
    const response: CancelSelectionForFloorResponse = yield call(callApi, payload);
    if (response?.isSuccess) {
      yield put(styleSelectionActions.cancelSelectionForFloorSuccess());
      SuccessToast('Succes!', 'Uw selectie is succesvol geannuleerd');
    } else {
      throw new Error('Cancel selection error');
    }
  } catch (error: unknown) {
    console.error('callCancelSelectionForFloorSaga', error);
    yield put(styleSelectionActions.cancelSelectionForFloorFailure());
    ErrorToast(undefined, error?.toString());
  }
}
