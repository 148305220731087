import { RootState } from '../../../store/types';

export const mainMenuDetailsIsLoadingSelector = (state: RootState) =>
  state.mainMenuDetailsReducer.mainMenuDetailsIsLoading;
export const mainMenuDetailsSelector = (state: RootState) => state.mainMenuDetailsReducer.mainMenuDetails;
export const noOfFloorsSelector = (state: RootState) => state.mainMenuDetailsReducer.mainMenuDetails?.floors;
export const idLotSelector = (state: RootState) => state.mainMenuDetailsReducer.mainMenuDetails?.idLot ?? -1;
export const selectedMaterialsSelector = (state: RootState) =>
  state.mainMenuDetailsReducer.mainMenuDetails?.selectedMaterials || [];
export const mainMenuDetailsHasErrorSelector = (state: RootState) =>
  state.mainMenuDetailsReducer.mainMenuDetailsHasError;
export const totalmaterialCountSelector = (state: RootState) =>
  state.mainMenuDetailsReducer.mainMenuDetails?.totalmaterialCount || 0;
