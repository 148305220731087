import { RootState } from '../../../store/types';

export const loginDetailsIsLoadingSelector = (state: RootState) => state.loginReducer.loginIsLoading;
export const tokenDetailsSelector = (state: RootState) => state.loginReducer.tokenDetails;
export const userDetailsSelector = (state: RootState) => state.loginReducer.clientInfoIsSuccess;
export const activeLotsSelector = (state: RootState) => state.loginReducer.activeLotsDetails || [];
export const isMultipleLotsSelector = (state: RootState) => state.loginReducer.userDetails?.isMultipleLotsPresent;
export const clientIdSelector = (state: RootState) => state.loginReducer.idClient ?? -1;
export const clientNameSelector = (state: RootState) => state.loginReducer.userDetails?.users?.name ?? '';
export const loginDetailsHasErrorSelector = (state: RootState) => state.loginReducer.loginHasError;

export const signupIsLoadingSelector = (state: RootState) => state.loginReducer.signupIsLoading;
export const signupIsSuccessSelector = (state: RootState) => state.loginReducer.signupIsSuccess;
export const signupHasErrorSelector = (state: RootState) => state.loginReducer.signupHasError;

export const acceptTermsAndConditionIsLoadingSelector = (state: RootState) =>
  state.loginReducer.acceptTermsAndConditionIsLoading;
export const acceptTermsAndConditionIsSuccessSelector = (state: RootState) =>
  state.loginReducer.acceptTermsAndConditionIsSuccess;

export const clientInfoIsLoadingSelector = (state: RootState) => state.loginReducer.clientInfoIsLoading || false;
export const clientInfoIsSuccessSelector = (state: RootState) => state.loginReducer.clientInfoIsSuccess;
export const clientInfoHasErrorSelector = (state: RootState) => state.loginReducer.clientInfoHasError;
