import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { CancelSelectionForTotalParams, CancelSelectionForTotalResponse } from '../../types';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { totalPriceOverviewActions } from '../totalPriceOverviewSlice';

async function callApi(props: CancelSelectionForTotalParams) {
  const queryParam = {
    clientId: props?.clientId,
    idLot: props?.idLot,
  };
  return ApiRequest(Method.GET, ENDPOINTS.cancelSelectionForTotal, undefined, queryParam);
}

export default function* callCancelSelectionForTotalSaga({ payload }: PayloadAction<CancelSelectionForTotalParams>) {
  try {
    const response: CancelSelectionForTotalResponse = yield call(callApi, payload);
    if (response?.isSuccess) {
      yield put(totalPriceOverviewActions.cancelSelectionForTotalSuccess());
      SuccessToast('Succes!', 'Annuleer de selectie succesvol');
    } else {
      throw new Error('Cancel selection error');
    }
  } catch (error: unknown) {
    console.error('callCancelSelectionForTotalSaga', error);
    yield put(totalPriceOverviewActions.cancelSelectionForTotalFailure());
    ErrorToast(undefined, error?.toString());
  }
}
