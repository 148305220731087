import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Color, ExistingSelection, Product, ProductGroup, SelectionType } from '../../types';
import { Swiper as SwiperClass } from 'swiper/types';
import { generateInitialSelection } from '../../util';
import {
  ROUTE_FLOOR_SELECTION,
  ROUTE_FLOORS,
  NESTED_ROUTE_SELECTION_SUMMARY,
  ROUTE_MAIN_MENU,
  ROUTE_FLOOR_PREVIEW,
} from '../../../../routes/constants';
import ProductSelector from '../productSelector/ProductSelector';
import ColorSelector from '../colorSelector/ColorSelector';
import { FloorDecoration } from '../../../styleSelection/types';
import { styleSelectionActions } from '../../../styleSelection/store/styleSelectionSlice';
import DecorationSelectionSkeleton from '../../../styleSelection/components/DecorationSelectionSkeleton';
import { Colors } from '../../../../utils/constant';

interface FlowGeneratorProps {
  floor: number;
  flowData: ProductGroup[];
  existingSelections: ExistingSelection[];
  loading: boolean;
}

const FlowGenerator: React.FC<FlowGeneratorProps> = ({ floor, flowData, existingSelections, loading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [curruntProductGroup, setCurruntProductGroup] = React.useState<ProductGroup>(flowData[0]); // curont product group
  const [curruntProduct, setCurruntProduct] = React.useState<Product | null>(flowData[0]?.product[0] || null); // this one holdibng the current product
  const [curruntColorSwipableData, setCurruntColorSwipableData] = React.useState<Color[] | undefined>(undefined); // this one holdibng the current product colors
  const [curruntColor, setCurruntColor] = React.useState<Color | null>(null); // this one holdibng the current product colors
  const [selections, setSelections] = React.useState<SelectionType>(
    generateInitialSelection(flowData, existingSelections),
  );

  useEffect(() => {
    if (flowData && flowData.length) {
      setCurruntProductGroup(flowData[0]);
      setCurruntProduct(flowData[0]?.product[0]);
      setCurruntColorSwipableData(undefined);
      setCurruntColor(null);
      setSelections(generateInitialSelection(flowData, existingSelections));
    }
  }, [existingSelections, flowData]);

  const handleSetNextProductGroup = () => {
    const currentIndex = flowData.findIndex((group) => group.id === curruntProductGroup.id);
    const nextIndex = (currentIndex + 1) % flowData.length;

    if (nextIndex !== 0) {
      setCurruntProductGroup(flowData[nextIndex]);
      setCurruntProduct(flowData[nextIndex].product[0]);
      setCurruntColorSwipableData(undefined);
      setCurruntColor(null);
    } else {
      Object.entries(selections).map(([key, value]) => {
        if (value.product?.id) {
          const productDetails: FloorDecoration = {
            idProduct: value.product?.id,
            floors: floor,
            description: value.product?.name,
            price: value.product.price,
            brochure: value.product?.brochure,
            idImage: value.product?.idImage,
            idTemplete: value.idTemplate,
            idDefaultTemplete: value.idDefaultTemplete,
            idChoosenStyle: value.idChoosenStyle,
            idMaterialColor: value.color?.idMaterialColor,
          };
          if (value.code === 1) {
            dispatch(styleSelectionActions.setSelectedFloorDecoration(productDetails));
          }
          if (value.code === 2) {
            dispatch(styleSelectionActions.setSelectedWindowDecoration(productDetails));
          }
          if (value.code === 3) {
            dispatch(styleSelectionActions.setSelectedCurtainDecoration(productDetails));
          }
        }
      });
      navigate(`${ROUTE_FLOORS}/${floor}/${NESTED_ROUTE_SELECTION_SUMMARY}`);
    }
  };

  const handleSwipeChange = (swiper: SwiperClass) => {
    const product = curruntProductGroup.product[swiper.realIndex];
    setCurruntProduct(product);
  };
  const handleColorSwipeChange = (swiper: SwiperClass) => {
    if (curruntColorSwipableData) {
      const color = curruntColorSwipableData[swiper.realIndex];
      setCurruntColor(color);
    }
  };

  const handleContinueClickProduct = () => {
    const productSelectedFromCurruntProductGroup = selections[curruntProductGroup.id]?.product;
    if (productSelectedFromCurruntProductGroup?.colors?.length) {
      setCurruntColorSwipableData(productSelectedFromCurruntProductGroup.colors);
      setCurruntColor(productSelectedFromCurruntProductGroup.colors[0]);
    } else {
      handleSetNextProductGroup();
    }
  };

  const handleContinueClickColor = () => {
    handleSetNextProductGroup();
  };

  const handleGoBackFromProductGroup = () => {
    const currentProductGroupIndex = flowData.findIndex((group) => group.id === curruntProductGroup.id);
    if (currentProductGroupIndex !== 0) {
      const previousProductGroupIndex = currentProductGroupIndex - 1;

      const prevProductGroup = flowData[previousProductGroupIndex];
      const prevProductGropSelections = selections[prevProductGroup.id];
      const previousProdutGroupSelectedProduct = prevProductGropSelections?.product;

      setCurruntProductGroup(prevProductGroup);
      setCurruntProduct(prevProductGroup.product[0]);

      if (previousProdutGroupSelectedProduct?.colors?.length) {
        setCurruntColorSwipableData(previousProdutGroupSelectedProduct.colors);
        setCurruntColor(previousProdutGroupSelectedProduct.colors[0]);
      } else {
        setCurruntColorSwipableData(undefined);
        setCurruntColor(null);
      }
    } else {
      navigate(ROUTE_FLOOR_SELECTION, { replace: true });
    }
  };
  const handleGoBackFromProductColor = () => {
    const currentProductGroupIndex = flowData.findIndex((group) => group.id === curruntProductGroup.id);
    const prevProductGroup = flowData[currentProductGroupIndex];
    setCurruntProduct(prevProductGroup.product[0]);
    setCurruntColorSwipableData(undefined);
    setCurruntColor(null);
  };

  const handleProductSelection = (product: Product, selected: boolean) => {
    if (selected) {
      const curruntProductGroupId = curruntProductGroup.id;
      setSelections((prev: SelectionType) => ({
        ...prev,
        [curruntProductGroupId]: {
          idTemplate: curruntProductGroup.idTemplete,
          idDefaultTemplate: curruntProductGroup.idDefaultTemplete,
          idChoosenStyle: prev[curruntProductGroupId].idChoosenStyle,
          code: curruntProductGroup.code,
          product: product,
          color: null,
        },
      }));
    } else {
      const curruntProductGroupId = curruntProductGroup.id;
      setSelections((prev: SelectionType) => ({
        ...prev,
        [curruntProductGroupId]: {
          idTemplate: curruntProductGroup.idTemplete,
          idDefaultTemplate: curruntProductGroup.idDefaultTemplete,
          idChoosenStyle: prev[curruntProductGroupId].idChoosenStyle,
          code: curruntProductGroup.code,
          product: null,
          color: null,
        },
      }));
    }
  };

  const handleColorSelection = (color: Color, selected: boolean) => {
    const curruntProductGroupId = curruntProductGroup.id;
    const existingSelection = selections[curruntProductGroupId];
    if (selected) {
      setSelections((prev: SelectionType) => ({ ...prev, [curruntProductGroupId]: { ...existingSelection, color } }));
    } else {
      const curruntProductGroupId = curruntProductGroup.id;
      setSelections((prev: SelectionType) => ({
        ...prev,
        [curruntProductGroupId]: { ...existingSelection, color: null },
      }));
    }
  };

  const handleClose = () => {
    setCurruntProductGroup(flowData[0]);
    setCurruntProduct(flowData[0].product[0]);
    setCurruntColorSwipableData(undefined);
    setCurruntColor(null);
    setSelections(generateInitialSelection(flowData, existingSelections));
    navigate(ROUTE_FLOOR_SELECTION, { replace: true });
  };

  const renderFlowGenerator = () => {
    return (
      <>
        {curruntProduct && !(curruntColorSwipableData && curruntColorSwipableData.length) && (
          <ProductSelector
            flowData={flowData}
            curruntProductGroup={curruntProductGroup}
            curruntProduct={curruntProduct}
            selections={selections}
            onSwipeChange={handleSwipeChange}
            onClickSelection={handleProductSelection}
            onClickContinue={handleContinueClickProduct}
            onClickGoBack={handleGoBackFromProductGroup}
            onClose={handleClose}
          />
        )}
        {curruntColorSwipableData?.length && (
          <ColorSelector
            onSwipeChange={handleColorSwipeChange}
            curruntColorSwipableData={curruntColorSwipableData}
            selections={selections}
            curruntProductGroup={curruntProductGroup}
            curruntProduct={curruntProduct}
            handleColorSelection={handleColorSelection}
            handleClose={handleClose}
            handleContinueClickColor={handleContinueClickColor}
            handleGoBackFromProductColor={handleGoBackFromProductColor}
            curruntColor={curruntColor}
          />
        )}
      </>
    );
  };

  return <>{loading ? <DecorationSelectionSkeleton bgColor={Colors.GreyPink} /> : renderFlowGenerator()}</>;
};

export default FlowGenerator;
