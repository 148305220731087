import React from 'react';
import styled from 'styled-components';
import { Colors } from '../utils/constant';

const PageOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max(100%, 800px);
  padding: 75px 0;
  @media screen and (max-width: 599px) {
    padding: 0;
    height: 100%;
  }
`;

const PageInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 900px;
  padding: 0 32px;
  @media screen and (max-width: 599px) {
    height: 100%;
    background-color: ${Colors.White};
    flex-direction: column;
    padding: 0;
  }
`;

const PageContentWrapper = styled.div`
  padding: 28px 40px;
  background-color: ${Colors.White} !important;
  width: 100%;

  @media screen and (max-width: 599px) {
    min-height: 100%;
    display: inline-table; // FIXME
    padding: 60px 32px 32px;
  }
`;

export default function WhiteBoxPageWrapper({ children }: { children: React.JSX.Element }) {
  return (
    <PageOuterWrapper>
      <PageInnerWrapper>
        <PageContentWrapper>{children}</PageContentWrapper>
      </PageInnerWrapper>
    </PageOuterWrapper>
  );
}
