import { styled } from '@mui/system';
import React from 'react';
import { SwiperSlide, Swiper } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper as SwiperClass } from 'swiper/types';
import { Colors, ImageBaseUrl } from '../../../../utils/constant';

import { ReactComponent as LeftIcon } from '../../../../assets/images/arrow-left.svg';
import { ReactComponent as RightIcon } from '../../../../assets/images/arrow-right.svg';
import { Color, Product, ProductGroup, SelectionType } from '../../types';
import DynamicImageSelector from '../../../../shared/components/OptionSelector/DynamicImageSelector';
import OptionSelectorRightContainer from '../../../../shared/components/OptionSelector/OptionSelectorRightContainer';
import priceFormatter from '../../../../utils/priceFormatter';
import { Box, Theme, useMediaQuery } from '@mui/material';
import MobileViewOptionSelectorTopContainer from '../../../../shared/components/OptionSelector/MobileViewOptionSelectorTopContainer';
import MobileViewOptionSelectorBottomContainer from '../../../../shared/components/OptionSelector/MobileViewOptionSelectorBottomContainer';

interface ColorSelectorProps {
  onSwipeChange?: (swiper: SwiperClass) => void;
  curruntColorSwipableData: Color[] | undefined;
  selections: SelectionType;
  curruntProductGroup: ProductGroup;
  curruntProduct: Product | null;
  handleColorSelection: (color: Color, selected: boolean) => void;
  handleClose: () => void;
  handleContinueClickColor: () => void;
  handleGoBackFromProductColor: () => void;
  curruntColor: Color | null;
}

const ColorSelector: React.FC<ColorSelectorProps> = ({
  onSwipeChange,
  curruntColorSwipableData,
  selections,
  curruntProductGroup,
  curruntProduct,
  handleColorSelection,
  handleClose,
  handleContinueClickColor,
  handleGoBackFromProductColor,
  curruntColor,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const checkIsColorSelected = (): boolean => {
    const curruntProductGroupId = curruntProductGroup.id;
    return !!selections && selections[curruntProductGroupId]?.color?.idMaterialColor === curruntColor?.idMaterialColor;
  };

  const checkMinimumOneColorSelected = (): boolean => {
    const curruntProductGroupId = curruntProductGroup.id;
    return !!selections && !!selections[curruntProductGroupId]?.color;
  };

  return (
    <>
      {isMobile && (
        <MobileViewOptionSelectorTopContainer
          title={'Kies jouw kleur'}
          subTitle={curruntProduct?.colorDescription ?? ''}
          bgColor={Colors.MarbleBlue}
        />
      )}
      <ImageSwiperContainer>
        <div className="swiper-button image-swiper-button-prev">
          <LeftIcon />
        </div>
        <div className="swiper-button image-swiper-button-next">
          <RightIcon />
        </div>
        <Swiper
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          onRealIndexChange={onSwipeChange}
          navigation={{
            nextEl: '.image-swiper-button-next',
            prevEl: '.image-swiper-button-prev',
          }}
        >
          {curruntColorSwipableData &&
            curruntColorSwipableData.map((color: Color) => {
              const encodedBlobImg = encodeURIComponent(color.colorImageBlodId);
              return (
                <SwiperSlide key={color.idMaterialColor}>
                  {!color.colorImageBlodId ? (
                    <div>Image Not available</div>
                  ) : (
                    <DynamicImageSelector
                      image={`${ImageBaseUrl + encodedBlobImg}`}
                      checked={checkIsColorSelected()}
                      setChecked={(selected) => handleColorSelection(color, selected)}
                      hasBrochure={curruntProduct?.brochure}
                      productId={curruntProduct?.id}
                    />
                  )}
                </SwiperSlide>
              );
            })}
        </Swiper>
      </ImageSwiperContainer>
      {isMobile ? (
        <MobileViewOptionSelectorBottomContainer
          handleContinueClick={handleContinueClickColor}
          price={`${priceFormatter(curruntProduct?.price)}`}
          hidePrice
          description={curruntProduct?.name ?? ''}
          name={curruntColor?.name ?? ''}
          handleGoBack={handleGoBackFromProductColor}
          continueBtnDisabled={!checkMinimumOneColorSelected()}
          backgroundColor={Colors.MarbleBlue}
          isMaterialSelected={true}
        />
      ) : (
        <OptionSelectorRightContainer
          title={'Kies jouw kleur'}
          subTitle={curruntProduct?.colorDescription ?? ''}
          handleClose={handleClose}
          handleContinueClick={handleContinueClickColor}
          handleGoBack={handleGoBackFromProductColor}
          price={`${priceFormatter(curruntProduct?.price)}`}
          hidePrice
          description={curruntProduct?.name ?? ''}
          name={curruntColor?.name ?? ''}
          bgColor={Colors.MarbleBlue}
          isMaterialSelected={true}
          continueBtnDisabled={!checkMinimumOneColorSelected()}
        />
      )}
    </>
  );
};

export default ColorSelector;

const ImageSwiperContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 550px;
  height: 100%;
  background-color: ${Colors.Mercury};
  @media screen and (max-width: 599px) {
    width: 100%;
    height: 100%;
    max-width: unset;
  }
`;
