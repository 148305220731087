import React from 'react';

import { Worker, Viewer } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import packageJson from '../../../../package.json';

const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

interface Props {
  pdfData: string;
}

const PdfViewer = ({ pdfData }: Props) => {
  if (pdfData.length === 0) return null;
  const dataUri = `data:application/pdf;base64,${pdfData}`;

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <>
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
        <Viewer fileUrl={dataUri} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </>
  );
};

export default PdfViewer;
