import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../../shared/components/CustomDialog';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import { Colors } from '../../../utils/constant';
import { ROUTE_MAIN_MENU } from '../../../routes/constants';
import { loginActions } from '../store/loginSlice';
import { useAppSelector, useAppDispatch } from '../../../store/types';
import {
  acceptTermsAndConditionIsLoadingSelector,
  acceptTermsAndConditionIsSuccessSelector,
} from '../store/loginSelectors';

const CheckBoxContainer = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  align-items: flex-start;
`;

const CheckBoxLabel = styled.p`
  color: ${Colors.TitanWhite};
  font-size: 14px;
  font-weight: 300;
  line-height: 140%;
  margin-left: 4px;
`;

const StyledLink = styled.a`
  color: rgb(224, 240, 246);
  font-size: 14px;
  font-weight: 300;
  line-height: 140%;
  margin-left: 4px;
  margin-right: 5px;
`;

const label = { inputProps: { 'aria-label': '' } };

interface TermConditiontModalProps {
  openModal: boolean;
  closeModal: () => void;
  clientId: number;
}

export default function TermsAndConditionModal(props: TermConditiontModalProps) {
  const { openModal, closeModal, clientId } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const acceptTermsAndConditionIsSuccess = useAppSelector(acceptTermsAndConditionIsSuccessSelector);
  const acceptTermsAndConditionIsLoading = useAppSelector(acceptTermsAndConditionIsLoadingSelector);

  useEffect(() => {
    if (acceptTermsAndConditionIsSuccess) {
      navigate(ROUTE_MAIN_MENU, { replace: true });
      dispatch(loginActions.resetAcceptTermsAndCondition());
    }
  }, [acceptTermsAndConditionIsSuccess, navigate, dispatch]);

  const [checked, setChecked] = React.useState(false);

  if (!openModal || !clientId) return null;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setChecked(event.target.checked);
  }

  function handleContinue() {
    if (clientId) {
      dispatch(loginActions.acceptTermsAndCondition(clientId));
    }
  }

  function onCloseModal() {
    closeModal();
    setChecked(false);
  }

  return (
    <CustomDialog
      open={openModal}
      handleClose={onCloseModal}
      title={'Privacyvoorwaarden'}
      cancelButtonText={'ANNULEREN'}
      handleCancelButtonPress={onCloseModal}
      primaryButtonText={'VERDER'}
      primaryButtonDisabled={!checked || acceptTermsAndConditionIsLoading}
      primaryButtonIsLoading={acceptTermsAndConditionIsLoading}
      primaryButtonWidth={'130px'}
      handlePrimaryButtonPress={handleContinue}
    >
      <CheckBoxContainer>
        <Checkbox
          checked={checked}
          onChange={handleChange}
          {...label}
          size="small"
          sx={{
            color: Colors.White,
            padding: 0,
            '&.Mui-checked': {
              color: Colors.White,
            },
          }}
        />
        <CheckBoxLabel>
          Ik heb de
          <StyledLink href={'https://www.stijlpartner.nl/info/privacy/'} target="_blank">
            privacyverklaring
          </StyledLink>
          gelezen en ga ermee akkoord
        </CheckBoxLabel>
      </CheckBoxContainer>
    </CustomDialog>
  );
}
