import React from 'react';
import { Button, CircularProgress, Dialog, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactComponent as CloseIcon } from '../../assets/images/close.svg';
import noop from '../../utils/noop';
import { Colors } from '../../utils/constant';

interface CustomDialogProps {
  open: boolean;
  handleClose: () => void;
  width?: string;
  title?: string;
  titleColor?: string;
  titleAlign?: 'flex-start' | 'center' | 'flex-end';
  showCloseButton?: boolean;
  footerAlign?: 'flex-start' | 'center' | 'flex-end';
  cancelButtonText?: string;
  cancelButtonIsLoading?: boolean;
  handleCancelButtonPress?: () => void;
  cancelButtonDisabled?: boolean;
  primaryButtonText?: string;
  primaryButtonIsLoading?: boolean;
  handlePrimaryButtonPress?: () => void;
  primaryButtonDisabled?: boolean;
  primaryButtonStartIcon?: React.ReactNode;
  primaryButtonEndIcon?: React.ReactNode;
  primaryButtonWidth?: string;
  children: React.ReactNode;
}

export default function CustomDialog(props: CustomDialogProps) {
  const {
    open,
    handleClose,
    width = '350px',
    title,
    titleColor = Colors.White,
    titleAlign = 'flex-start',
    showCloseButton = true,
    footerAlign = 'flex-end',
    cancelButtonText,
    cancelButtonIsLoading = false,
    handleCancelButtonPress,
    cancelButtonDisabled = false,
    primaryButtonText,
    primaryButtonIsLoading = false,
    handlePrimaryButtonPress,
    primaryButtonDisabled = false,
    primaryButtonStartIcon = null,
    primaryButtonEndIcon = null,
    primaryButtonWidth,
    children,
  } = props;
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      scroll={'body'}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
          width,
          padding: '32px',
          background: Colors.MarbleBlue,
          margin: '0 !important',
        },
      }}
    >
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          {showCloseButton && (
            <IconButton onClick={handleClose} sx={{ padding: 0 }}>
              <CloseIcon stroke={Colors.White} width={18} />
            </IconButton>
          )}
        </Box>
        {title && (
          <Box sx={{ width: '100%' }} display={'flex'} justifyContent={titleAlign} mt={1}>
            {title && (
              <Typography
                variant={'h2'}
                sx={{
                  color: titleColor,
                  fontFamily: 'NeulisAlt',
                  fontWeight: 400,
                  fontStyle: 'italic',
                  fontSize: 24,
                  lineHeight: 'normal',
                }}
              >
                {title}
              </Typography>
            )}
          </Box>
        )}
        {children}
        {(cancelButtonText || primaryButtonText) && (
          <Box mt={2} display={'flex'} justifyContent={footerAlign} gap={1}>
            {cancelButtonText && (
              <Button
                disableElevation
                variant="text"
                onClick={handleCancelButtonPress ?? noop}
                disabled={cancelButtonDisabled || cancelButtonIsLoading}
                sx={{
                  '& .MuiButton-root': {
                    color: Colors.White,
                    fontSize: '14px',
                  },
                  color: Colors.White,
                  height: '36px',
                  padding: '2px 12px 0',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                }}
              >
                {cancelButtonIsLoading ? 'Loading...' : cancelButtonText}
              </Button>
            )}
            {primaryButtonText && (
              <Button
                disableElevation
                variant="contained"
                onClick={handlePrimaryButtonPress ?? noop}
                disabled={primaryButtonDisabled || primaryButtonIsLoading}
                startIcon={primaryButtonStartIcon}
                endIcon={primaryButtonEndIcon}
                sx={{
                  '& .MuiButton-root': {
                    color: Colors.White,
                    backgroundColor: Colors.GreyPink,
                    fontSize: '14px',
                  },
                  color: Colors.White,
                  textTransform: 'uppercase',
                  height: '36px',
                  width: primaryButtonWidth ?? 'unset',
                  padding: '2px 12px 0',
                  fontWeight: 700,
                  textWrap: 'nowrap',
                  overflow: 'hidden',
                  '&:disabled': {
                    color: Colors.White,
                    backgroundColor: Colors.GreyPink,
                    opacity: 0.6,
                  },
                  '&:hover': {
                    backgroundColor: Colors.GreyPink,
                  },
                }}
              >
                {primaryButtonIsLoading ? <CircularProgress size={20} color="inherit" /> : primaryButtonText}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
