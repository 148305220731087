import { createTheme } from '@mui/material/styles';
import { Colors } from '../utils/constant';

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.GreyPink,
    },
    secondary: {
      main: Colors.MilkWhite,
    },
    warning: {
      main: Colors.CarrotOrange,
    },
    error: {
      main: Colors.ReddishOrange,
    },
    success: {
      main: Colors.KiwiGreen,
    },
  },
  components: {},
  typography: {
    allVariants: {
      fontFamily: 'Public-sans',
      textTransform: 'none',
    },
  },
});

export default theme;
