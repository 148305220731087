import React, { useEffect } from 'react';
import PageHeader from '../../../shared/components/pageHeader/PageHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, useMediaQuery } from '@mui/material';
import { Colors } from '../../../utils/constant';

import { ReactComponent as HomeHashTagIcon } from '../../../assets/images/homeHashTag.svg';
import { GENERIC_FLOW_PAGE, ROUTE_FLOORS, ROUTE_MAIN_MENU } from '../../../routes/constants';
import { InnerPageWrapper, PageWrapper } from '../../../shared/components/BasicComoponent';
import { styled } from '@mui/system';
import { RootState, useAppSelector } from '../../../store/types';
import { clientIdSelector } from '../../login/store/loginSelectors';

import { styleSelectionActions } from '../store/styleSelectionSlice';
import FloorImageDetails from '../components/FloorImageDetailsMobile';
import FloorImageDetailsDesktop from '../components/FloorImageDetailsDesktop';
import useApiResponseHandler from '../../../hooks/UseApiResponseHandler.hook';

const FloorPreviewPage: React.FC = () => {
  const navigate = useNavigate();
  const { floor } = useParams();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { floorImageIsLoading, floorImageData } = useGetFloorImage();

  useApiResponseHandler(
    floorImageIsLoading,
    null,
    () => {
      if (!floorImageData?.floorImageBlobId) {
        handleNavigateGenericFlow();
      }
    },
    () => {},
  );

  const handleBack = () => {
    navigate(ROUTE_MAIN_MENU);
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleNavigateGenericFlow = () => {
    if (floor) {
      navigate(`${ROUTE_FLOORS}/${parseInt(floor)}/${GENERIC_FLOW_PAGE}`);
    }
  };

  return (
    <>
      <PageHeader
        background={isSmallScreen ? Colors.GreyPink : undefined}
        buttonText={'Home'}
        buttonIcon={<HomeHashTagIcon stroke={Colors.White} />}
        onButtonClick={handleBack}
      />
      <PageWrapper>
        <ContentWrapper isSmallScreen={isSmallScreen}>
          {isSmallScreen ? (
            <FloorImageDetails
              floorImageData={floorImageData}
              floorImageIsLoading={floorImageIsLoading}
              handleClose={handleClose}
              handleContinue={handleNavigateGenericFlow}
            />
          ) : (
            <FloorImageDetailsDesktop
              floorImageData={floorImageData}
              floorImageIsLoading={floorImageIsLoading}
              handleClose={handleClose}
              handleContinue={handleNavigateGenericFlow}
            />
          )}
        </ContentWrapper>
      </PageWrapper>
    </>
  );
};

export default FloorPreviewPage;

const useGetFloorImage = () => {
  const dispatch = useDispatch();
  const { floor } = useParams();
  const clientId = useAppSelector(clientIdSelector);

  const floorImageIsLoading = useSelector((state: RootState) => state.styleSelectionReducer.floorImageIsLoading);
  const floorImageData = useSelector((state: RootState) => state.styleSelectionReducer.floorImageData);

  useEffect(() => {
    if (clientId && floor) {
      dispatch(styleSelectionActions.getFloorImage({ IdClient: clientId, floor: parseInt(floor) }));
    }
  }, [dispatch, clientId, floor]);

  return { floorImageIsLoading, floorImageData };
};

const ContentWrapper = styled(InnerPageWrapper)(({ isSmallScreen }: { isSmallScreen: boolean }) => ({
  height: isSmallScreen ? '100%' : '600px',
}));
