import React from 'react';
import styled from 'styled-components';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download.svg';
import { ReactComponent as CheckboxEmptyIcon } from '../../../assets/images/checkboxEmpty.svg';
import { ReactComponent as CheckboxCheckedIcon } from '../../../assets/images/checkboxChecked.svg';
import IconButton from '@mui/material/IconButton';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import { styleSelectionActions } from '../../../features/styleSelection/store/styleSelectionSlice';
import { mainMenuDetailsSelector } from '../../../features/mainMenu/store/mainMenuSelectors';
import { Colors } from '../../../utils/constant';
import { downloadBrochureIsLoadingSelector } from '../../../features/styleSelection/store/styleSelectionSelectors';

const StyledImageContainer = styled.div<{ src: string }>`
  background-color: ${Colors.Boulder};
  background: url(${(props) => props.src}) no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
`;

const StyledDownloadBrochure = styled.div`
  position: absolute;
  bottom: 20px;
  left: 24px;
`;

const StyledCheckBox = styled.button`
  position: absolute;
  bottom: 20px;
  right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const CheckBoxLabel = styled.p`
  color: ${Colors.White};
  font-size: 16px;
  font-weight: 600;
  line-height: 86%;
  letter-spacing: 0.52px;
  text-transform: uppercase;
  margin-left: 4px;
  //text-shadow: black 0 0 4px;
`;

interface DynamicImageSelectorProps {
  image: string;
  checked: boolean;
  setChecked: (a: boolean) => void;
  hasBrochure?: boolean;
  productId?: number;
}

function DynamicImageSelector({
  image,
  checked,
  setChecked,
  hasBrochure = false,
  productId = -1,
}: DynamicImageSelectorProps) {
  const dispatch = useAppDispatch();
  const downloadBrochureIsLoading = useAppSelector(downloadBrochureIsLoadingSelector);
  const mainMenuDetails = useAppSelector(mainMenuDetailsSelector);

  const isQuoteSigned = mainMenuDetails?.isQuoteSigned;

  // const imageUrl = image?.replace(/\s/g, '%20');
  // const imageUrl = encodeURIComponent(image);

  function handleDownloadBrochure() {
    dispatch(styleSelectionActions.fetchDownloadBrochure({ productId: productId }));
  }

  function handleCheckBoxClick() {
    if (!isQuoteSigned) {
      setChecked(!checked);
    }
  }

  return (
    <StyledImageContainer src={image}>
      {hasBrochure && (
        <StyledDownloadBrochure>
          <IconButton
            onClick={handleDownloadBrochure}
            disabled={downloadBrochureIsLoading}
            sx={{ opacity: downloadBrochureIsLoading ? 0.6 : 1, width: '40px', height: '40px', paddingBottom: '15px' }}
          >
            <DownloadIcon />
          </IconButton>
        </StyledDownloadBrochure>
      )}
      <StyledCheckBox onClick={handleCheckBoxClick}>
        {checked ? <CheckboxCheckedIcon /> : <CheckboxEmptyIcon />}
        <CheckBoxLabel>{checked ? 'gekozen' : 'selecteer'}</CheckBoxLabel>
      </StyledCheckBox>
    </StyledImageContainer>
  );
}

export default React.memo(DynamicImageSelector);
