import React from 'react';
import Dialog from '@mui/material/Dialog';
import PdfViewer from './PdfViewer';

interface ShowQuotePreviewProps {
  open: boolean;
  handleClose: () => void;
  pdf: string;
}

export default function ShowQuotePreview(props: ShowQuotePreviewProps) {
  const { open, handleClose, pdf } = props;
  if (!open) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={'lg'}
      scroll={'body'}
      sx={{
        '& .MuiDialog-paper': { width: '70%', padding: '15px' },
      }}
    >
      <PdfViewer pdfData={pdf} />
    </Dialog>
  );
}
