export const ROUTE_LOGIN = '/login';
export const ROUTE_MAIN_MENU = '/main-menu';
export const ROUTE_GENERIC_FLOW = '/generic-flow';
export const ROUTE_MY_QUOTE = '/my-quote';
export const ROUTE_MAKE_APPOINTMENT = '/make-appointment';
export const ROUTE_FLOOR_SELECTION = '/floor-selection';
export const ROUTE_FLOORS = '/floors';
export const ROUTE_TOTAL_PRICE_OVERVIEW = '/total-price-overview';

export const NESTED_ROUTE_SELECTION_SUMMARY = 'summary';
export const ROUTE_FLOOR_PREVIEW = 'floor-preview';
export const GENERIC_FLOW_PAGE = 'generic-flow-page';
