import { Colors } from '../../utils/constant';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Button, CircularProgress } from '@mui/material';
import React from 'react';

const BasicLoaderWrapper = styled.div`
  color: ${Colors.White};
`;

export function BasicPageLoader() {
  return (
    <BasicLoaderWrapper>
      <CircularProgress color="inherit" size={30} />
    </BasicLoaderWrapper>
  );
}

export const ErrorMessageWrapper = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${Colors.ReddishOrange};
`;

export const BasicErrorWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.SoftPeach};
`;

export const BasicErrorMessage = styled.p`
  font-size: 16px;
  text-align: center;
  margin: 0 48px;
`;

export const CancelButton = styled(Button)<{ disabled?: boolean }>`
  padding: 16px !important;
  border-radius: 2px !important;
  background: transparent !important;
  color: ${Colors.Black} !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

export const ConfirmButton = styled(Button)<{ disabled?: boolean }>`
  padding: 16px !important;
  border-radius: 2px !important;
  background: ${Colors.GreyPink} !important;
  color: ${Colors.White} !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max(100%, 800px);
  @media screen and (max-width: 599px) {
    padding: 0;
    height: 100%;
  }
`;

export const InnerPageWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 900px;
  padding: 0 32px;
  @media screen and (max-width: 599px) {
    height: 100%;
    flex-direction: column;
    padding: 0;
  }
`;

export const PageContainer = styled.div`
  padding: 28px 40px;
  background-color: ${Colors.White};
  width: 100%;
  @media screen and (max-width: 599px) {
    height: 100%;
    padding: 60px 32px 32px;
  }
`;

export const SwiperContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 550px;
  height: 100%;
  background-color: ${Colors.Mercury};
  @media screen and (max-width: 599px) {
    width: 100%;
    height: 100%;
    max-width: unset;
  }
`;
