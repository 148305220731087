import { takeLatest } from 'redux-saga/effects';
import { totalPriceOverviewActions } from './totalPriceOverviewSlice';
import callFetchTotalPriceOverviewSaga from './sagas/callFetchTotalPriceOverviewSaga';
import callSignQuoteSaga from './sagas/callSignQuoteSaga';
import callCancelSelectionForTotalSaga from './sagas/callCancelSelectionForTotalSaga';
import callFetchMainMenuDetailsSaga from '../../mainMenu/store/sagas/callFetchMainMenuDetailsSaga';

export default function* totalPriceOverviewSaga() {
  yield takeLatest(totalPriceOverviewActions.fetchTotalPriceOverview, callFetchTotalPriceOverviewSaga);

  yield takeLatest(totalPriceOverviewActions.signQuote, callSignQuoteSaga);
  yield takeLatest(totalPriceOverviewActions.signQuoteSuccess, callFetchMainMenuDetailsSaga);

  yield takeLatest(totalPriceOverviewActions.cancelSelectionForTotal, callCancelSelectionForTotalSaga);
  yield takeLatest(totalPriceOverviewActions.cancelSelectionForTotalSuccess, callFetchMainMenuDetailsSaga);
}
