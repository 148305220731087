import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { ChoosenStylesPayload } from '../../types';
import { ENDPOINTS } from '../../../../api/constants';
import { styleSelectionActions } from '../styleSelectionSlice';
import getNumberOrNull from '../../../../utils/getNumberOrNull';

async function callApi(props: ChoosenStylesPayload[]) {
  const payload = props.map((i) => ({
    idClient: getNumberOrNull(i.idClient),
    idProduct: getNumberOrNull(i.idProduct),
    idTemplete: getNumberOrNull(i.idTemplete),
    floors: getNumberOrNull(i.floors),
    idMaterialColor: getNumberOrNull(i.idMaterialColor),
    idDefaultTemplate: getNumberOrNull(i.idDefaultTemplate),
    idChoosenStyle: getNumberOrNull(i.idChoosenStyle),
    price: getNumberOrNull(i.price),
  }));
  return ApiRequest(Method.POST, ENDPOINTS.productEditChoosenStyles, undefined, undefined, payload);
}

export default function* callEditChoosenStylesSaga({ payload }: PayloadAction<ChoosenStylesPayload[]>) {
  try {
    // @ts-ignore
    const response = yield call(callApi, payload);
    if (response?.isSuccess) {
      yield put(styleSelectionActions.editChoosenStylesSuccess());
      SuccessToast('Materialen geselecteerd!', 'Gekozen stijlen zijn succesvol bijgewerkt');
    } else {
      throw response?.message;
    }
  } catch (error: unknown) {
    console.error('callEditChoosenStylesSaga', error);
    yield put(styleSelectionActions.editChoosenStylesFailure());
    ErrorToast(undefined, error?.toString());
  }
}
