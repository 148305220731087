/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { loginActions } from '../loginSlice';
import { UserDetails } from '../../types';

async function callApi(clientId: number) {
  const pathParam = {
    Id: clientId,
  };

  return ApiRequest(Method.GET, ENDPOINTS.clientInfo, pathParam, undefined);
}

export default function* callClientInfo({ payload }: PayloadAction<number>) {
  try {
    const resp: UserDetails = yield call(callApi, payload);
    yield put(loginActions.fetchCleintInfoSuccess(resp));
  } catch (error: unknown) {
    yield put(loginActions.fetchClientInfoFailure());
  }
}
