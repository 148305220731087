import { call, put, select } from 'redux-saga/effects';
import { Method } from '../../../../api/types';
import { MainMenuDetails } from '../../types';
import { mainMenuDetailsActions } from '../mainMenuSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { clientIdSelector } from '../../../login/store/loginSelectors';

async function callApi(idClient: number) {
  const queryParam = {
    idClient,
  };
  return ApiRequest(Method.GET, ENDPOINTS.mainMenu, undefined, queryParam);
}

export default function* callFetchMainMenuDetailsSaga() {
  const idClient: number = yield select(clientIdSelector);
  try {
    const menuDetails: MainMenuDetails = yield call(callApi, idClient);
    if (menuDetails.clientId) {
      yield put(mainMenuDetailsActions.fetchMainMenuDetailsSuccess(menuDetails));
    } else {
      throw new Error('Client not found');
    }
  } catch (error: unknown) {
    console.error('callFetchMainMenuDetailsSaga', error);
    yield put(mainMenuDetailsActions.fetchMainMenuDetailsFailure());
  }
}
