import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../../shared/components/CustomDialog';
import styled from 'styled-components';
import SelectField from '../../../shared/components/SelectField';
import { Colors } from '../../../utils/constant';
import { ROUTE_MAIN_MENU } from '../../../routes/constants';
import { loginActions } from '../store/loginSlice';
import { useAppSelector, useAppDispatch } from '../../../store/types';
import {
  activeLotsSelector,
  acceptTermsAndConditionIsSuccessSelector,
  clientInfoIsLoadingSelector,
  clientInfoHasErrorSelector,
} from '../store/loginSelectors';
import useApiResponseHandler from '../../../hooks/UseApiResponseHandler.hook';

const CheckBoxContainer = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: row;
  align-items: flex-start;
  flex-flow: column;
`;

const ContentText = styled.p`
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: ${Colors.TitanWhite};
`;

interface TermConditiontModalProps {
  openModal: boolean;
  closeModal: () => void;
}

export default function ActivelotModal(props: TermConditiontModalProps) {
  const { openModal, closeModal } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const acceptTermsAndConditionIsSuccess = useAppSelector(acceptTermsAndConditionIsSuccessSelector);
  const activeLotsData = useAppSelector(activeLotsSelector);
  const clientInfoLoading = useAppSelector(clientInfoIsLoadingSelector);
  const clientInfoError = useAppSelector(clientInfoHasErrorSelector);

  useEffect(() => {
    if (acceptTermsAndConditionIsSuccess) {
      navigate(ROUTE_MAIN_MENU, { replace: true });
      dispatch(loginActions.resetAcceptTermsAndCondition());
    }
  }, [acceptTermsAndConditionIsSuccess, navigate, dispatch]);

  const [lotValue, setLotValue] = React.useState(0);

  //   if (!openModal || !clientId) return null;

  function handleChange(value: number) {
    setLotValue(value);
  }

  function handleContinue() {
    if (lotValue) {
      dispatch(loginActions.fetchClientInfo(lotValue));
    }
  }

  function onCloseModal() {
    closeModal();
    setLotValue(0);
  }

  const suuccessClientInfoHandler = (): void => {
    dispatch(loginActions.setIdConst(lotValue));
  };

  const errorClientInfoHandler = (): void => {};

  useApiResponseHandler(clientInfoLoading, clientInfoError, suuccessClientInfoHandler, errorClientInfoHandler);

  return (
    <CustomDialog
      open={openModal}
      handleClose={onCloseModal}
      title={'Kies jouw woning'}
      cancelButtonText={'ANNULEREN'}
      handleCancelButtonPress={onCloseModal}
      primaryButtonText={'Opslaan'}
      primaryButtonDisabled={lotValue === 0}
      // primaryButtonIsLoading={acceptTermsAndConditionIsLoading}
      primaryButtonWidth={'130px'}
      handlePrimaryButtonPress={handleContinue}
    >
      <CheckBoxContainer>
        <ContentText>Selecteer de woning waar je je materialen voor wilt selecteren.</ContentText>
        <SelectField
          label="Naam woning"
          names={activeLotsData}
          multiple={false}
          labelColor={Colors.TitanWhite}
          onChange={(e) => handleChange(e.target.value)}
        />
      </CheckBoxContainer>
    </CustomDialog>
  );
}
