import styled from 'styled-components';
import React from 'react';
import { Colors } from '../../../utils/constant';
import { Skeleton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/types';

const StyledTitle = styled.h2`
  color: ${Colors.White};
  font-family: NeulisAlt, sans-serif;
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  line-height: 30px;
`;

const StyledFloorType = styled(Typography)`
  color: ${Colors.White};
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 160% !important;
  margin-top: 10px !important;
`;

const StyledDescription = styled.p`
  color: ${Colors.White};
  font-size: 16px;
  font-weight: 300;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.64px;
  margin-top: 10px;
`;

const TopContainer = styled.div<{ $bgColor: string }>`
  background-color: ${(props) => props.$bgColor};
  padding: 44px 32px 24px;
  height: fit-content;
`;

interface MobileViewTopContainerProps {
  title: string;
  subTitle: string;
  bgColor: string;
}

export default function MobileViewOptionSelectorTopContainer({
  title,
  subTitle,
  bgColor,
}: MobileViewTopContainerProps) {
  const slectedFloorType = useSelector((state: RootState) => state.genericFlow.slectedFloorType);

  return (
    <TopContainer $bgColor={bgColor}>
      <StyledTitle>{title}</StyledTitle>
      <StyledFloorType>{slectedFloorType}</StyledFloorType>
      <StyledDescription>{subTitle}</StyledDescription>
    </TopContainer>
  );
}

export function MobileViewOptionSelectorTopContainerSkeleton({ bgColor }: { bgColor: string }) {
  return (
    <TopContainer $bgColor={bgColor}>
      <Skeleton variant="rectangular" sx={{ width: '75%', height: '30px' }} />
      <Skeleton variant="rectangular" sx={{ width: '100%', height: '76px', marginTop: '14px' }} />
    </TopContainer>
  );
}
