import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { Method } from '../../../../api/types';
import { SubmitSignatureParams, SubmitSignatureResponse } from '../../types';
import { myQuoteActions } from '../myQuoteSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';

async function callApi(props: SubmitSignatureParams) {
  const pathParam = {
    id: props?.id,
  };
  const payload = {
    fileName: props?.fileName,
    data: props?.data,
    location: props?.location,
  };
  return ApiRequest(Method.POST, ENDPOINTS.submitSignatureForSignQuote, pathParam, undefined, payload);
}

export default function* callSubmitSignatureSaga({ payload }: PayloadAction<SubmitSignatureParams>) {
  try {
    // TODO
    const signatureResponse: SubmitSignatureResponse = yield call(callApi, payload);
    SuccessToast('Succes!', 'Offerte succesvol ondertekend');
    yield put(myQuoteActions.submitSignatureIsSuccess());
  } catch (error: unknown) {
    console.error('callSubmitSignatureSaga', error);
    yield put(myQuoteActions.submitSignatureHasError());
    ErrorToast(undefined, error?.toString());
  }
}
