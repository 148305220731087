import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../utils/constant';

export const StyledInput = styled.input`
  width: 100%;
  height: 34px;
  border-radius: 5px;
  border: 1px solid ${Colors.CoolGrey};
  background: rgba(255, 255, 255, 0.6);
  padding: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
  letter-spacing: 0.64px;
  color: ${Colors.White};
  &:focus {
    outline: none;
    opacity: 1;
  }
  &::placeholder {
    color: rgba(80, 96, 107, 0.7);
    opacity: 0.6;
  }
`;

const ErrorText = styled.p`
  color: ${Colors.CoralPink};
  font-size: 14px;
  text-align: left;
  margin-top: 2px;
`;

const StyledSpan = styled.span`
  color: ${Colors.CoralPink};
  font-size: 14px;
  margin-bottom: 8px;
  margin-left: 4px;
`;

const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledHint = styled.p`
  color: ${Colors.HitGrey};
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  margin-top: 2px;
`;

const StyledLabel = styled.p<{
  labelColor: string;
  disabled: boolean;
}>`
  font-size: 16px;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.64px;
  margin-bottom: 8px;
  text-align: left;
  color: ${({ labelColor }) => labelColor};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

interface Props {
  label?: string;
  name: string;
  type?: string;
  required?: boolean;
  markAsRequired?: boolean;
  placeholder?: string;
  hint?: string;
  disabled?: boolean;
  labelColor?: string;
}

export default function TextInputFieldFilled(props: Props) {
  const {
    label,
    name,
    type = 'text',
    required = false,
    markAsRequired = false,
    placeholder = '',
    hint = '',
    disabled = false,
    labelColor = Colors.White,
  } = props;
  const renderError = (message: string) => <ErrorText>{message}</ErrorText>;
  const { errors } = useFormikContext();
  // @ts-ignore
  const hasError = errors[name];

  return (
    <>
      {label && (
        <StyledLabelContainer>
          <StyledLabel labelColor={labelColor} disabled={disabled}>
            {label}
          </StyledLabel>
          {!disabled && markAsRequired && <StyledSpan>*</StyledSpan>}
        </StyledLabelContainer>
      )}
      <Field
        as={StyledInput}
        placeholder={placeholder}
        type={type}
        required={required}
        name={name}
        disabled={disabled}
      />
      {hint && !hasError && <StyledHint>{hint}</StyledHint>}
      <ErrorMessage name={name} render={renderError} />
    </>
  );
}
