/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CancelSelectionForFloorParams,
  ChoosenStylesPayload,
  FetchDownloadBrochure,
  FetchFloorColorImage,
  FetchFloorDecorationImage,
  FetchWindowColorImage,
  FetchWindowDecorationImage,
  FloorDecoration,
  FloorMaterialSelectionState,
  GetFloorImageRequest,
  GetFloorImageResponse,
} from '../types';

const initialState: FloorMaterialSelectionState = {
  floorColorsIsLoading: false,
  floorColors: undefined,
  floorColorsHasError: false,
  selectedFloorColor: undefined,

  floorDecorationIsLoading: false,
  floorDecoration: [],
  floorDecorationHasError: false,
  selectedFloorDecoration: undefined,

  windowColorsIsLoading: false,
  windowColors: undefined,
  windowColorsHasError: false,
  selectedWindowColor: undefined,

  windowDecorationIsLoading: false,
  windowDecoration: [],
  windowDecorationHasError: false,
  selectedWindowDecoration: undefined,
  selectedCurtainDecoration: undefined,

  editChoosenStylesIsLoading: false,
  editChoosenStylesIsSuccess: false,

  floorDecorationImageIsLoading: false,
  floorColorImageIsLoading: false,
  windowColorImageIsLoading: false,
  windowDecorationImageIsLoading: false,
  downloadBrochureIsLoading: false,

  cancelSelectionForFloorIsLoading: false,
  cancelSelectionForFloorIsSuccess: false,

  floorImageIsLoading: false,
  floorImageData: null,
  floorImageHasError: false,
};

const styleSelectionSlice = createSlice({
  name: 'styleSelection',
  initialState,
  reducers: {
    setSelectedFloorDecoration(state, action: PayloadAction<FloorDecoration | undefined>) {
      state.selectedFloorDecoration = action.payload;
    },
    setSelectedWindowDecoration(state, action: PayloadAction<FloorDecoration | undefined>) {
      state.selectedWindowDecoration = action.payload;
    },
    setSelectedCurtainDecoration(state, action: PayloadAction<FloorDecoration | undefined>) {
      state.selectedCurtainDecoration = action.payload;
    },

    resetSelectedDecorations(state) {
      state.selectedFloorColor = undefined;
      state.selectedFloorDecoration = undefined;
      state.selectedWindowColor = undefined;
      state.selectedWindowDecoration = undefined;
      state.selectedCurtainDecoration = undefined;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    editChoosenStyles(state, action: PayloadAction<ChoosenStylesPayload[]>) {
      state.editChoosenStylesIsLoading = true;
      state.editChoosenStylesIsSuccess = false;
    },
    editChoosenStylesSuccess(state) {
      state.editChoosenStylesIsLoading = false;
      state.editChoosenStylesIsSuccess = true;
    },
    editChoosenStylesFailure(state) {
      state.editChoosenStylesIsLoading = false;
    },
    resetEditChoosenStyles(state) {
      state.editChoosenStylesIsLoading = false;
      state.editChoosenStylesIsSuccess = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchFloorDecorationImage(state, action: PayloadAction<FetchFloorDecorationImage>) {
      state.floorDecorationImageIsLoading = true;
    },
    fetchFloorDecorationImageLoaded(state) {
      state.floorDecorationImageIsLoading = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchFloorColorImage(state, action: PayloadAction<FetchFloorColorImage>) {
      state.floorColorImageIsLoading = true;
    },
    fetchFloorColorImageLoaded(state) {
      state.floorColorImageIsLoading = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchWindowDecorationImage(state, action: PayloadAction<FetchWindowDecorationImage>) {
      state.windowDecorationImageIsLoading = true;
    },
    fetchWindowDecorationImageLoaded(state) {
      state.windowDecorationImageIsLoading = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchWindowColorImage(state, action: PayloadAction<FetchWindowColorImage>) {
      state.windowColorImageIsLoading = true;
    },
    fetchWindowColorImageLoaded(state) {
      state.windowColorImageIsLoading = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchDownloadBrochure(state, action: PayloadAction<FetchDownloadBrochure>) {
      state.downloadBrochureIsLoading = true;
    },
    fetchDownloadBrochureLoaded(state) {
      state.downloadBrochureIsLoading = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cancelSelectionForFloor(state, action: PayloadAction<CancelSelectionForFloorParams>) {
      state.cancelSelectionForFloorIsLoading = true;
      state.cancelSelectionForFloorIsSuccess = false;
    },
    cancelSelectionForFloorSuccess(state) {
      state.cancelSelectionForFloorIsLoading = false;
      state.cancelSelectionForFloorIsSuccess = true;
    },
    cancelSelectionForFloorFailure(state) {
      state.cancelSelectionForFloorIsLoading = false;
    },
    resetCancelSelectionForFloor(state) {
      state.cancelSelectionForFloorIsLoading = false;
      state.cancelSelectionForFloorIsSuccess = false;
    },

    getFloorImage(state, action: PayloadAction<GetFloorImageRequest>) {
      state.floorImageIsLoading = true;
      state.floorImageData = null;
      state.floorImageHasError = false;
    },
    getFloorImageSuccess(state, action: PayloadAction<GetFloorImageResponse>) {
      state.floorImageIsLoading = false;
      state.floorImageData = action.payload;
    },
    getFloorImageFailure(state) {
      state.floorImageIsLoading = false;
      state.floorImageHasError = true;
    },
  },
});

// Actions
export const styleSelectionActions = styleSelectionSlice.actions;

// Reducer
const styleSelectionReducer = styleSelectionSlice.reducer;
export default styleSelectionReducer;
