import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL } from './constants';
import omitBy from 'lodash/omitBy';
import { Method } from './types';
import store from '../store/store';

type ParamType = Record<string, unknown>;
type PayloadType = Record<string, unknown> | Record<string, unknown>[];
type BodyType = Record<string, unknown>;

export function getOptimizeQueryParam(obj: { [key: string]: unknown }) {
  return omitBy(obj, (v) => v === undefined || v === null || v === '');
}

function getUrlWithPathParam(url: string, pathParam: ParamType) {
  const spited = url.split(/[{}]/);
  return spited.reduce((acc, i) => {
    if (pathParam[i]) {
      return acc + `${pathParam[i]}`;
    } else {
      return acc + `${i}`;
    }
  }, '');
}

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosApiInstance.interceptors.request.use(
  // @ts-ignore
  async (config: AxiosRequestConfig) => {
    const accessToken = store.getState().loginReducer.token;
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export async function ApiRequest(
  method: Method,
  url: string,
  pathParam: ParamType = {},
  queryParam: ParamType = {},
  payload: PayloadType = {},
): Promise<BodyType> {
  return axiosApiInstance({
    method,
    url: getUrlWithPathParam(url, pathParam),
    baseURL: BASE_URL,
    headers: {},
    params: getOptimizeQueryParam(queryParam),
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => error);
}
