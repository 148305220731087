import React, { useEffect, useState } from 'react';
import PageHeader from '../../../shared/components/pageHeader/PageHeader';
import { ReactComponent as BrushBigIcon } from '../../../assets/images/brushBig.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg';
import { ReactComponent as DocumentTextIcon } from '../../../assets/images/documentText.svg';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import { Button, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE_FLOOR_SELECTION, ROUTE_LOGIN, ROUTE_MAKE_APPOINTMENT, ROUTE_MY_QUOTE } from '../../../routes/constants';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import { mainMenuDetailsActions } from '../store/mainMenuSlice';
import { mainMenuDetailsSelector } from '../store/mainMenuSelectors';
import { clientIdSelector, clientNameSelector } from '../../login/store/loginSelectors';
import { loginActions } from '../../login/store/loginSlice';
import { Colors } from '../../../utils/constant';
import { myQuoteActions } from '../../myQuote/store/myQuoteSlice';
import { getQuoteDataSelector } from '../../myQuote/store/myQuoteSelectors';
import ShowQuotePreview from '../components/ShowQuotePreview';
import { use } from 'i18next';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max(100%, 800px);
  overflow-x: clip;
`;

const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${Colors.AshGrey};
  border-left: 1px solid ${Colors.AshGrey};
`;

const RowContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledGridItem = styled(Grid)`
  width: 640px;
  @media screen and (max-width: 899px) {
    width: 600px;
  }
  @media screen and (max-width: 350px) {
    width: 480px;
  }
  @media screen and (max-width: 300px) and (max-height: 660px) {
    width: 480px;
  }
  @media screen and (max-height: 650px) {
    width: 580px;
  }
  @media screen and (max-width: 599px) and (min-width: 350px) {
    width: 640px;
  }
`;

const StyledGrid = styled(Box)`
  border-bottom: 1px solid ${Colors.AshGrey};
  border-right: 1px solid ${Colors.AshGrey};
  display: flex;
  width: 160px;
  height: 170px;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 899px) {
    width: 150px;
    height: 160px;
  }
  @media screen and (max-width: 350px) {
    width: 120px;
    height: 120px;
  }
  @media screen and (max-width: 300px) and (max-height: 660px) {
    width: 120px;
    height: 120px;
  }
  @media screen and (max-height: 650px) {
    width: 145px;
    height: 145px;
  }
  @media screen and (max-width: 599px) and (min-width: 350px) {
    width: 160px;
    height: 145px;
  }
`;

const TitleText = styled.p`
  color: ${Colors.White};
  font-size: 18px;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
`;

const WelcomeText = styled.p`
  color: ${Colors.White};
  font-size: 28px;
  font-weight: 600;
  line-height: 160%; /* 28.8px */
  font-style: italic;
  font-family: NeulisAlt, sans-serif;
`;

const ClientNameText = styled.p`
  color: ${Colors.White};
  font-size: 32px;
  font-weight: 700;
  line-height: 160%; /* 28.8px */
  font-style: italic;
  font-family: NeulisAlt, sans-serif;
`;

const StyledTitle = styled.h1`
  color: ${Colors.White};
  font-size: 32px;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  padding: 0 32px 32px;
  text-align: left;
  display: none;

  @media screen and (max-width: 599px) {
    display: flex;
  }
`;

const ActionButton = styled(Button)<{ disabled?: boolean; backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor} !important;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  text-align: left;
  padding: 8px;
  gap: 8px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

export default function MainMenuPage() {
  const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mainMenuDetails = useAppSelector(mainMenuDetailsSelector);
  const getQuoteData = useAppSelector(getQuoteDataSelector);
  const clientId = useAppSelector(clientIdSelector);
  const clinetName = useAppSelector(clientNameSelector);

  const [showPdf, sewtShowPdf] = useState(false);

  const clientStatusCode = mainMenuDetails?.clientStatusCode;

  useEffect(() => {
    if (clientId) {
      dispatch(mainMenuDetailsActions.fetchMainMenuDetails());
    }
  }, [clientId, dispatch]);

  useEffect(() => {
    if (clientStatusCode && clientStatusCode === 7) {
      dispatch(myQuoteActions.getQuote(clientId));
    }
  }, [clientStatusCode, clientId, dispatch]);

  function handleFloorSelection() {
    navigate(ROUTE_FLOOR_SELECTION);
  }

  function handleMyQuote() {
    if (clientStatusCode && clientStatusCode === 7) {
      sewtShowPdf(true);
    } else {
      navigate(ROUTE_MY_QUOTE);
    }
  }

  function handleMakeAnAppointment() {
    navigate(ROUTE_MAKE_APPOINTMENT);
  }

  function handleLogout() {
    dispatch(loginActions.setIdConst(undefined));
    dispatch(loginActions.resetLoginDetails());
    navigate(ROUTE_LOGIN, { replace: true });
  }

  const disableFloorSelection = !mainMenuDetails?.selectStyle;
  const disableMakeAnAppointment = !mainMenuDetails?.makeAppointment;
  const disableMyQuote = !mainMenuDetails?.myQuote;

  return (
    <>
      <PageHeader buttonText={'Uitloggen'} onButtonClick={handleLogout} />
      <PageWrapper>
        {/* <StyledTitle>mijn.stijlpartner</StyledTitle> */}
        <Grid container justifyContent="center">
          <StyledGridItem item>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                marginBottom: '20px',
                marginLeft: isMobileScreen ? '30px' : undefined,
              }}
            >
              <WelcomeText>Welkom,</WelcomeText>
              <ClientNameText>{clinetName} !</ClientNameText>
            </Box>
          </StyledGridItem>
        </Grid>
        <ContentWrapper>
          <RowContainer>
            <StyledGrid />
            <StyledGrid>
              <ActionButton
                disabled={disableFloorSelection}
                backgroundColor={'rgba(189, 136, 144, 0.7)'}
                onClick={handleFloorSelection}
              >
                <TitleText>Mijn huis samenstellen</TitleText>
                <BrushBigIcon />
              </ActionButton>
            </StyledGrid>
            <StyledGrid />
            <StyledGrid />
          </RowContainer>
          <RowContainer>
            <StyledGrid />
            <StyledGrid />
            <StyledGrid>
              {isMobileScreen && (
                <ActionButton
                  disabled={disableMakeAnAppointment}
                  backgroundColor={'rgba(52, 62, 71, 0.7)'}
                  onClick={handleMakeAnAppointment}
                >
                  <TitleText>Afspraak maken in de stijlkamer</TitleText>
                  <CalendarIcon />
                </ActionButton>
              )}
            </StyledGrid>
            <StyledGrid>
              {!isMobileScreen && (
                <ActionButton
                  disabled={disableMakeAnAppointment}
                  backgroundColor={'rgba(52, 62, 71, 0.7)'}
                  onClick={handleMakeAnAppointment}
                >
                  <TitleText>Afspraak maken in de stijlkamer</TitleText>
                  <CalendarIcon />
                </ActionButton>
              )}
            </StyledGrid>
          </RowContainer>
          {isMobileScreen && (
            <RowContainer>
              <StyledGrid />
              <StyledGrid />
              <StyledGrid />
              <StyledGrid />
            </RowContainer>
          )}
          <RowContainer>
            <StyledGrid />
            <StyledGrid>
              {!isMobileScreen && (
                <ActionButton
                  disabled={disableMyQuote}
                  backgroundColor={'rgba(75, 124, 138, 0.7)'}
                  onClick={handleMyQuote}
                >
                  <TitleText>
                    {clientStatusCode && clientStatusCode === 7 ? 'Offerte ondertekend' : 'Mijn persoonlijke offerte'}
                  </TitleText>
                  <DocumentTextIcon />
                </ActionButton>
              )}
            </StyledGrid>
            <StyledGrid>
              {isMobileScreen && (
                <ActionButton
                  disabled={disableMyQuote}
                  backgroundColor={'rgba(75, 124, 138, 0.7)'}
                  onClick={handleMyQuote}
                >
                  <TitleText>
                    {clientStatusCode && clientStatusCode === 7 ? 'Offerte ondertekend' : 'Mijn persoonlijke offerte'}
                  </TitleText>
                  <DocumentTextIcon />
                </ActionButton>
              )}
            </StyledGrid>
            <StyledGrid />
          </RowContainer>
        </ContentWrapper>
      </PageWrapper>
      <ShowQuotePreview open={showPdf} handleClose={() => sewtShowPdf(false)} pdf={getQuoteData?.quote ?? ''} />
    </>
  );
}
