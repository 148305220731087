import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, Theme, Box } from '@mui/material';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../../shared/components/pageHeader/PageHeader';

import { Colors } from '../../../utils/constant';
import { ROUTE_MAIN_MENU } from '../../../routes/constants';
import { FetchMaterialsToSelectParam } from '../types';

import { ReactComponent as HomeHashTagIcon } from '../../../assets/images/homeHashTag.svg';
import { InnerPageWrapper, PageWrapper } from '../../../shared/components/BasicComoponent';
import FlowGenerator from '../components/flowGenerator/FlowGenerator';
import { genericFlowActions } from '../store/genericFlowSlice';
import { RootState, useAppSelector } from '../../../store/types';
import { clientIdSelector } from '../../login/store/loginSelectors';
import useApiResponseHandler from '../../../hooks/UseApiResponseHandler.hook';
import ErrorToast from '../../../shared/components/toast/ErrorToast';

const GenericFlowPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { floor } = useParams();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const materialsToSelect = useSelector((state: RootState) => state.genericFlow.materialList);
  const materialListLoading = useSelector((state: RootState) => state.genericFlow.materialListLoading);
  const materialListError = useSelector((state: RootState) => state.genericFlow.materialListError);

  const existingSelections = useSelector((state: RootState) => state.genericFlow.existingSelection);
  const existingSelectionsLoading = useSelector((state: RootState) => state.genericFlow.existingSelectionLoading);
  const existingSelectionsError = useSelector((state: RootState) => state.genericFlow.existingSelectionError);

  const mobilePageHeaderColor = useSelector((state: RootState) => state.genericFlow.mobilePageHeaderColor);

  const clientId = useAppSelector(clientIdSelector);

  useEffect(() => {
    if (floor && clientId) {
      const payload: FetchMaterialsToSelectParam = {
        ClientId: clientId,
        Floor: Number(floor),
      };
      dispatch(genericFlowActions.getMaterialsToSelect(payload));
      dispatch(genericFlowActions.getExistingSelection({ IdClient: clientId, Floor: Number(floor) }));
    }
  }, [clientId, dispatch, floor]);

  useApiResponseHandler(
    materialListLoading,
    materialListError,
    () => {},
    () => {
      ErrorToast('Oops!', 'Something went wrong. Please try again later.');
    },
  );
  useApiResponseHandler(
    existingSelectionsLoading,
    existingSelectionsError,
    () => {},
    () => {
      ErrorToast('Oops!', 'Something went wrong. Please try again later.');
    },
  );

  function handleBack() {
    navigate(ROUTE_MAIN_MENU);
  }

  return (
    <>
      <PageHeader
        background={isSmallScreen ? mobilePageHeaderColor : undefined}
        buttonText={'Home'}
        buttonIcon={<HomeHashTagIcon stroke={Colors.White} />}
        onButtonClick={handleBack}
      />
      <PageWrapper>
        <ContentWrapper isSmallScreen={isSmallScreen}>
          {materialsToSelect && (
            <FlowGenerator
              floor={Number(floor)}
              flowData={materialsToSelect ?? []}
              existingSelections={existingSelections ?? []}
              loading={existingSelectionsLoading || materialListLoading}
            />
          )}
        </ContentWrapper>
      </PageWrapper>
    </>
  );
};

export default GenericFlowPage;

const ContentWrapper = styled(InnerPageWrapper)(({ isSmallScreen }: { isSmallScreen: boolean }) => ({
  height: isSmallScreen ? '100%' : '600px',
}));
