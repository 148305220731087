import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import { ReactComponent as SuccessIcon } from '../../../assets/images/notification/success.svg';
import { Colors } from '../../../utils/constant';

const StyledTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: ${Colors.White};
  margin: 0;
  line-height: 22px;
`;

const StyledDescription = styled.p`
  font-size: 14px;
  font-weight: 300;
  line-height: 120%;
  color: ${Colors.White};
`;

export default function SuccessToast(title?: string, description?: string) {
  return toast(
    <Box display={'flex'} flexDirection={'row'} gap={2}>
      <Box width={'76px'} height={'76px'}>
        <SuccessIcon width={76} height={76} />
      </Box>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        {title && <StyledTitle>{title}</StyledTitle>}
        {description && <StyledDescription>{description}</StyledDescription>}
      </Box>
    </Box>,
    { className: 'custom-success' },
  );
}
