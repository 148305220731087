import { takeLatest } from 'redux-saga/effects';
import { styleSelectionActions } from './styleSelectionSlice';
import callEditChoosenStylesSaga from './sagas/callEditChoosenStylesSaga';
import callFetchDownloadBrochureSaga from './sagas/callFetchDownloadBrochureSaga';
import callCancelSelectionForFloorSaga from './sagas/callCancelSelectionForFloorSaga';
import callFetchMainMenuDetailsSaga from '../../mainMenu/store/sagas/callFetchMainMenuDetailsSaga';
import callGetFloorImageSaga from './sagas/callGetFloorImageSaga';

export default function* styleSelectionSaga() {
  yield takeLatest(styleSelectionActions.editChoosenStyles, callEditChoosenStylesSaga);
  yield takeLatest(styleSelectionActions.editChoosenStylesSuccess, callFetchMainMenuDetailsSaga);

  yield takeLatest(styleSelectionActions.fetchDownloadBrochure, callFetchDownloadBrochureSaga);

  yield takeLatest(styleSelectionActions.cancelSelectionForFloor, callCancelSelectionForFloorSaga);
  yield takeLatest(styleSelectionActions.cancelSelectionForFloorSuccess, callFetchMainMenuDetailsSaga);
  yield takeLatest(styleSelectionActions.getFloorImage, callGetFloorImageSaga);
}
