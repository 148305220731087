export const FloorColors = [
  'rgba(189, 136, 144, 1)',
  'rgba(151, 132, 142, 1)',
  'rgba(75, 124, 138, 1)',
  'rgba(98, 128, 142, 1)',
  'rgba(120, 132, 145, 1)',
];

export const Colors = {
  White: '#ffffff',
  Mercury: '#e7e7e7',
  MilkWhite: '#fcfcfc',
  TitanWhite: '#e0f0f6',
  Platinum: '#e0e3eb',
  AshGrey: '#bbbbbb',
  HitGrey: '#a0aec0',
  CoolGrey: '#9a9fa5',
  SteelGrey: '#788491',
  Boulder: '#757575',
  CharcoalGrey: '#343e47',
  Black: '#000000',
  KiwiGreen: '#b4d74b',
  CarrotOrange: '#ec9e29',
  ReddishOrange: '#ec4c29',
  CoralPink: '#fc8181',
  GreyPink: '#bd8890',
  WarmGrey: '#97848e',
  BeetleGreen: '#4b7c8a',
  SlateBlue: '#62808e',
  MarbleBlue: '#456a76',
  NileBlue: '#25334f',
  BlackPearl: '#0c1b2a',
  SantaGrey: '#9da5af',
  PowderBlue: '#c6dae0',
  SoftPeach: '#efefef',
  PastelGrey: '#d0d0d0',
  GreyNickel: '#c1c1c1',
  PearlBush: '#ebdbdd',
  MineralGreen: '#345f6b',
  MidGrey: '#50606B',
  red: '#FF0000',
};

export const ImageBaseUrl = 'https://stijlpartnerdevstorage.blob.core.windows.net/spproductimages/';
