import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../store/types';
import { clientIdSelector, userDetailsSelector } from '../features/login/store/loginSelectors';
import React, { useEffect } from 'react';
import { ROUTE_MAIN_MENU } from './constants';

export default function GuestWrapper({ children }: { children: React.JSX.Element }) {
  const navigate = useNavigate();
  const clientId = useAppSelector(clientIdSelector);
  const userDetails = useAppSelector(userDetailsSelector);

  useEffect(() => {
    if (clientId !== -1 && !userDetails?.isFirstLogin) {
      navigate(ROUTE_MAIN_MENU);
    }
  }, [clientId, navigate, userDetails]);

  return children;
}
