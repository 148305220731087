import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchMainMenuDetailsParams, MainMenuDetails, MainMenuState } from '../types';

const initialState: MainMenuState = {
  mainMenuDetailsIsLoading: false,
  mainMenuDetails: undefined,
  mainMenuDetailsHasError: false,
};

const mainMenuDetailsSlice = createSlice({
  name: 'mainMenuDetails',
  initialState,
  reducers: {
    fetchMainMenuDetails(state) {
      state.mainMenuDetailsIsLoading = true;
      state.mainMenuDetailsHasError = false;
    },
    fetchMainMenuDetailsSuccess(state, action: PayloadAction<MainMenuDetails>) {
      state.mainMenuDetailsIsLoading = false;
      state.mainMenuDetails = action.payload;
    },
    fetchMainMenuDetailsFailure(state) {
      state.mainMenuDetailsIsLoading = false;
      state.mainMenuDetailsHasError = true;
    },
  },
});

// Actions
export const mainMenuDetailsActions = mainMenuDetailsSlice.actions;

// Reducer
const mainMenuDetailsReducer = mainMenuDetailsSlice.reducer;
export default mainMenuDetailsReducer;
