import { RootState } from '../../../store/types';

export const styleRoomAvailabilityIsLoadingSelector = (state: RootState) =>
  state.bookStyleRoomReducer.styleRoomAvailabilityIsLoading;
export const styleRoomAvailabilitiesSelector = (state: RootState) => state.bookStyleRoomReducer.styleRoomAvailabilities;
export const styleRoomAvailabilityHasErrorSelector = (state: RootState) =>
  state.bookStyleRoomReducer.styleRoomAvailabilityHasError;

export const bookAppointmentIsLoadingSelector = (state: RootState) =>
  state.bookStyleRoomReducer.bookAppointmentIsLoading;
export const bookAppointmentIsSuccessSelector = (state: RootState) =>
  state.bookStyleRoomReducer.bookAppointmentIsSuccess;
export const bookAppointmentHasErrorSelector = (state: RootState) => state.bookStyleRoomReducer.bookAppointmentHasError;
