import { useAppSelector } from '../store/types';
import { clientIdSelector } from '../features/login/store/loginSelectors';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_LOGIN } from './constants';

export default function AuthorizedWrapper({ children }: { children: React.JSX.Element }) {
  const navigate = useNavigate();
  const clientId = useAppSelector(clientIdSelector);

  useEffect(() => {
    if (clientId === -1) {
      navigate(ROUTE_LOGIN);
    }
  }, [clientId, navigate]);

  return children;
}
