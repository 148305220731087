import { PayloadAction } from '@reduxjs/toolkit';
import { ApiRequest } from '../../../../api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { GetExistingSelectionParam, ExistingSelection } from '../../types';
import { call, put } from 'redux-saga/effects';
import { genericFlowActions } from '../genericFlowSlice';

async function callApi(props: GetExistingSelectionParam) {
  const queryParam = {
    ...props,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getExistingSelections, undefined, queryParam);
}

export default function* callGetExistingSelectionSaga({ payload }: PayloadAction<GetExistingSelectionParam>) {
  try {
    const materialsToSelect: ExistingSelection[] = yield call(callApi, payload);
    yield put(genericFlowActions.getExistingSelectionSuccess(materialsToSelect));
  } catch (error: unknown) {
    console.error('callFetchMaterialToSelectSaga', error);
    yield put(genericFlowActions.getExistingSelectionFailure());
  }
}
