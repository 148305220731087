/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GenericFlowState,
  ProductGroup,
  FetchMaterialsToSelectParam,
  GetExistingSelectionParam,
  ExistingSelection,
} from '../types';
import { Colors } from '../../../utils/constant';

const initialState: GenericFlowState = {
  materialListLoading: false,
  materialList: [],
  materialListError: false,

  existingSelection: [],
  existingSelectionLoading: false,
  existingSelectionError: false,

  mobilePageHeaderColor: Colors.GreyPink,
  slectedFloorType: '',
};

const genericFlowSlice = createSlice({
  name: 'genericFlow',
  initialState,
  reducers: {
    getMaterialsToSelect(state, action: PayloadAction<FetchMaterialsToSelectParam>) {
      state.materialListLoading = true;
      state.materialListError = false;
    },
    getMaterialsToSelectSuccess(state, action: PayloadAction<ProductGroup[]>) {
      state.materialListLoading = false;
      state.materialList = action.payload;
    },
    getMaterialsToSelectFailure(state) {
      state.materialListLoading = false;
      state.materialListError = true;
    },

    getExistingSelection(state, action: PayloadAction<GetExistingSelectionParam>) {
      state.existingSelectionLoading = true;
      state.existingSelectionError = false;
    },
    getExistingSelectionSuccess(state, action: PayloadAction<ExistingSelection[]>) {
      state.existingSelectionLoading = false;
      state.existingSelectionError = false;
      state.existingSelection = action.payload;
    },
    getExistingSelectionFailure(state) {
      state.existingSelectionLoading = false;
      state.existingSelectionError = true;
      state.existingSelection = [];
    },

    setMobilePageHeaderColor(state, action: PayloadAction<string>) {
      state.mobilePageHeaderColor = action.payload;
    },
    setSelectedFloorType(state, action: PayloadAction<string>) {
      state.slectedFloorType = action.payload;
    },
  },
});

export const genericFlowActions = genericFlowSlice.actions;

const genericFlowReducer = genericFlowSlice.reducer;

export default genericFlowReducer;
