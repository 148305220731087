import { takeLatest } from 'redux-saga/effects';
import { loginActions } from './loginSlice';
import callFetchLoginSaga from './sagas/callFetchLoginSaga';
import callFetchSignupSaga from './sagas/callFetchSignupSaga';
import callTermsAndCondition from './sagas/callAcceptTermsAndCondition';
import callClientInfo from './sagas/callClientInfoSaga';

export default function* loginSaga() {
  yield takeLatest(loginActions.fetchLoginDetails, callFetchLoginSaga);
  yield takeLatest(loginActions.fetchSignup, callFetchSignupSaga);
  yield takeLatest(loginActions.acceptTermsAndCondition, callTermsAndCondition);
  yield takeLatest(loginActions.fetchClientInfo, callClientInfo);
}
